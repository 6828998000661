import GoodSurfNavBar from 'components/Navbars/GoodSurfNavBar';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// import { useHistory } from 'react-router-dom';
import ApiService from 'services/ApiService.js';
import LocationFooter from '../components/Locations/LocationFooter';
import FooterBlack from "components/Footers/FooterBlack.js";
import AboutUsSection from './about-us-sections/AboutUsSection';
import { Helmet } from 'react-helmet-async';

const AboutUs = () => {
    // const history = useHistory();
    const [data, setData] = useState();
    const [locations, setLocations] = useState();

    useEffect(() => {
        (async function callApi() {
            const data = await ApiService.get('about-us');
            setData(data);
        })();
    }, []);

    useEffect(() => {
        (async function callApi() {
            const data = await ApiService.get('locations?_limit=-1');
            setLocations(_.orderBy(data, (item) => item.order));
        })();
    }, []);

    useEffect(() => {
        document.body.classList.add("presentation-page");
        document.body.classList.add("sidebar-collapse");
        document.documentElement.classList.remove("nav-open");
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;

        // we need to add a script for the github buttons
        let script = document.createElement("script");
        script.src = "https://buttons.github.io/buttons.js";
        script.id = "github-buttons-script-id";
        document.body.appendChild(script);

        return function cleanup() {
            document.body.classList.remove("presentation-page");
            document.body.classList.remove("sidebar-collapse");

            // we need to remove the script when we change the page
            script.parentNode.removeChild(script);
        };
    });

    return (
        <>
            <Helmet>
                <title>About Us | Good Surf Now!</title>
                <meta property="og:title" key="og:title" content="About Us | Good Surf Now!" />
                <meta property="og:type" key="og:type" content="website" />
                <meta property="og:url" key="og:url" content="https://www.goodsurfnow.co.nz/about-us" />
                <meta property="og:description" key="og:description" content="Owwwwtearoa's frothiest independent surf report & forecasting website/app is given freely so no one is denied access to high quality tools for connecting to the joy and healing power of surfing" />
                <link rel="canonical" href="https://www.goodsurfnow.co.nz/about-us/" />
                <meta name="description" content="Owwwwtearoa's frothiest independent surf report & forecasting website/app is given freely so no one is denied access to high quality tools for connecting to the joy and healing power of surfing" />
            </Helmet>
            <GoodSurfNavBar locations={locations} />
            <div className="wrapper">
                <AboutUsSection data={data} />
                <LocationFooter locations={locations} />
                <FooterBlack />
            </div>
        </>
    )
}

export default AboutUs;