import React from 'react';
import { Button, Modal, Card, CardText, CardBody, CardTitle } from 'reactstrap';
import { detect } from 'detect-browser';
import appleIcon from '../../assets/icons/Navigation_Action_2x.png';
import gsnLogo from '../../assets/logo/gsn_logo_mobile.png';
import './InstallPWAIosPrompt.scss';
const browser = detect();

const  InstallPWAIosPrompt = ({show, onClose}) => {
  
  return (
    <Modal isOpen={show} centered>
      <Card>
        <div className="install-pwa-logo-div">
          <img
              className="mx-auto install-pwa-logo-img"
              width="50px"
              src={gsnLogo}
              alt="Icon"
          />
        </div>
        <CardBody>
          <CardTitle className="text-center m-0">
            <h3>Get App</h3>
          </CardTitle>
          <>
              {
                browser && browser.name !== 'safari' ? (<CardText className="text-center">
                Open the website on Safari browser
              </CardText>): <span/>
              }
              <CardText className="text-center">
                Tap
                <img
                  src={appleIcon}
                  style={{ margin: 'auto 8px 8px' }}
                  className=""
                  alt="Add to homescreen"
                  width="20"
                />
                then &quot;Add to Home Screen&quot;
              </CardText>
              <div className="d-flex justify-content-center">
                <Button onClick={onClose}>Close</Button>
              </div>
            </>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default InstallPWAIosPrompt;