import React, { useEffect, useState } from "react";
import _ from "lodash";
import CamThumbnail from "./CamThumbnail";
import "./HomeCams.scss";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import localStorageService from "../../services/LocalStorageService";

const HomeCams = () => {
  const camInfo = useSelector((state) => state?.camera?.info);
  const [recent, setRecent] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const recentLocation = localStorageService.getLocations() || [];
    setRecent(recentLocation);
  }, []);

  const recentLocationMap = recent.reduce((acc, location, index) => {
    acc[location.slug] = index;
    return acc;
  }, {});

  const sortedCams = _.sortBy(camInfo?.cams, (cam) => {
    return recentLocationMap[cam.slug];
  });

  console.log("Sorted Cams", sortedCams);

  const handleLocationClick = (location) => {
    history.push({
      pathname: `/location/${location.slug}`,
    });
  };

  return (
    <div>
      {/* <div key="div_1" style={{ height: 63 }}></div> */}
      {camInfo?.cams?.length ? (
        <div>
          <div key="div_3" className="home-cams-container">
            {_.map(sortedCams, (cam, index) => (
              <div key={index} onClick={() => handleLocationClick(cam)}>
                <CamThumbnail cam={cam} />
              </div>
            ))}
          </div>
          <div key="div_2" style={{ height: 20 }}></div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default HomeCams;
