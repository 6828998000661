import React from "react";
import { Row, Col } from "reactstrap";
import _ from "lodash";
import WindIcon from "./WindIcon";
import "./WindChart.scss";
import moment from "moment-timezone";

const WindChart = ({ location, data }) => {
  return (
    <div className="wind-chart-container">
      <Row className="ml-0 mr-0 pl-0 pr-0" style={{ marginBottom: "10px" }}>
        {_.map(data, (item, index) => {
          const time = moment.tz(item.timestamp, "Pacific/Auckland");
          // const dayLightWinterTime = moment(
          //   process.env.REACT_APP_DAY_LIGHT_WINTER,
          //   "DD-MM-YYYY hh:mm A"
          // );
          // const dayLightSummerTime = moment(
          //   process.env.REACT_APP_DAY_LIGHT_SUMMER,
          //   "DD-MM-YYYY hh:mm A"
          // );
          // if (time.isAfter(dayLightWinterTime)) {
          //   time.add(1, "h");
          // }

          // if (time.isAfter(dayLightSummerTime)) {
          //   time.subtract(1, "h");
          // }
          // TODO: Day light summer time.
          return (
            <Col
              key={index}
              className="ml-0 mr-0 pl-0 pr-0"
              style={{
                backgroundColor:
                  parseInt(index / 4) % 2 > 0
                    ? "rgba(68, 170, 213, 0.1)"
                    : "rgba(0, 0, 0, 0)",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div className="chart-time-line">
                <div>
                  <b>{time.format("ddd")}</b>
                </div>
                <div>{time.format("DD")}</div>
                <div>{time.format("ha")}</div>
              </div>
            </Col>
          );
        })}
      </Row>
      <Row className="ml-0 mr-0 pl-0 pr-0">
        {_.map(data, (item, index) => {
          return (
            <Col
              key={index}
              className="ml-0 mr-0 pl-0 pr-0"
              style={{
                backgroundColor:
                  parseInt(index / 4) % 2 > 0
                    ? "rgba(68, 170, 213, 0.1)"
                    : "rgba(0, 0, 0, 0)",
                display: "flex",
                justifyContent: "center",
                height: "90px",
              }}
            >
              <WindIcon
                speed={item && item.windSpeed && item.windSpeed.toFixed(0)}
                direction={item.windDirection}
                iconColor="#FD2DBF"
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default WindChart;
