import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import './LocationAdSlotBody.scss';

const LocationAdSlotBody = ({
    slot
}) => {
    const handleClick = (link) => {
        if (link) {
            window.location = link
        }
    }
    return slot.img ? (
        <Container className="location-ad-slot-body-container">
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                    <div onClick={() => handleClick(slot.link)} className={`${slot.link ? 'location-ad-slot location-ad-slot-small': 'location-ad-slot-small'}`}>
                        <img alt="slot-1" className="location-ad-slot-body" style={{transition: 'none !important'}} src={slot.img} />
                    </div>
                </Col>
            </Row>
        </Container>
    ) :
        <div />
}

export default LocationAdSlotBody;