import React from "react";
import { Row, Col } from "reactstrap";
import moment from "moment";
import WindArrow from "./WindArrow";
import { getDirectionLabel } from "../../tools/utills";
import "./Tooltip.scss";
import DirectionArrow from "./DirectionArrow";

const Tooltip = ({
  waveDirection,
  waveDirection8s,
  wavePeriodB8s,
  wavePeriod,
  wavePeriod8s,
  waveDirectionB8s,
  windDirection,
  windSpeed,
  waveHeight,
  waveHeight8s,
  timestamp,
}) => {
  return (
    <div className="chart-tooltip">
      <Row className="p-0 m-0">
        <Col className="p-0 m-0">
          <div className="tooltip-timestamp">
            <b>{`${moment(timestamp).format("ha")} ${moment(timestamp).format("ddd")} `}</b>
            <b style={{ color: "gray" }}>{` ${moment(timestamp).format("DD")} ${moment(timestamp).format("MMM")}`}</b>
          </div>
        </Col>
      </Row>
      <Row className="p-0 m-0">
        <Col className="p-0 m-0 tooltip-wind">
          <WindArrow speed={windSpeed && windSpeed.toFixed(0)} direction={windDirection} iconColor="#FD2DBF" />
        </Col>
        <Col className="p-0 m-0 tooltip-wind">
          <b>{`${windSpeed ? windSpeed.toFixed(0) + "kt" : "-"} `}</b>
        </Col>
        <Col className="p-0 m-0 tooltip-wind"></Col>
        <Col className="p-0 m-0 tooltip-wind">
          <b>{getDirectionLabel(windDirection)}</b>
        </Col>
        <Col className="p-0 m-0 tooltip-wind">
          <b>{`${windDirection ? windDirection.toFixed(0) + "°" : "-"}`}</b>
        </Col>
      </Row>
      <Row className="p-0 m-0">
        <Col className="p-0 m-0 tooltip-wave8s">
          <DirectionArrow color="#2196F3" size={24} direction={waveDirectionB8s} />
        </Col>
        <Col className="p-0 m-0 tooltip-wave8s">
          <b>{`${waveHeight && waveHeight8s? (waveHeight - waveHeight8s).toFixed(1) + "m" : "-"} `}</b>
        </Col>
        <Col className="p-0 m-0 tooltip-wave8s">
          <b>{`${wavePeriodB8s ? wavePeriodB8s.toFixed(0) + "s" : "-"}`}</b>
        </Col>
        <Col className="p-0 m-0 tooltip-wave8s">
          <b>{getDirectionLabel(waveDirectionB8s)}</b>
        </Col>
        <Col className="p-0 m-0 tooltip-wave8s">
          <b>{`${waveDirectionB8s ? waveDirectionB8s.toFixed(0) + "°" : "-"}`}</b>
        </Col>
      </Row>
      <Row className="p-0 m-0">
        <Col className="p-0 m-0 tooltip-wave">
          <DirectionArrow color="#1A237E" size={24} direction={waveDirection8s} />
        </Col>
        <Col className="p-0 m-0 tooltip-wave">
          <b>{`${waveHeight && waveHeight8s ? (waveHeight - (waveHeight - waveHeight8s)).toFixed(1) + "m" : "-"} `}</b>
        </Col>
        <Col className="p-0 m-0 tooltip-wave">
          <b>{`${wavePeriod8s ? wavePeriod8s.toFixed(0) + "s" : "-"}`}</b>
        </Col>
        <Col className="p-0 m-0 tooltip-wave">
          <b>{getDirectionLabel(waveDirection8s)}</b>
        </Col>
        <Col className="p-0 m-0 tooltip-wave">
          <b>{`${waveDirection8s ? waveDirection8s.toFixed(0) + "°" : "-"}`}</b>
        </Col>
      </Row>
    </div>
  );
};

export default Tooltip;
