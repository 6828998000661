import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Row, Col} from 'reactstrap';
import { fetchData } from '../../services/NiwaTidesDataService';
import './TidesChart.scss';
import ReactTooltip from 'react-tooltip';

const TidesChart = ({
    location
}) => {
    const [data,setData] = useState([]);
    useEffect(()=>{
        (async function callApi () {
            if (location) {
                const data = await fetchData(location);
                setData(data);
            }
        })();
    }, [location])
    const getTideTime =(isHi, timestamp) =>{
        return (
                <span className={isHi ? 'hiText' : 'lawText'}>{`${moment.tz(timestamp, "Pacific/Auckland").format('h.mm')}`}<span className='ampm'>{`${moment.tz(timestamp, "Pacific/Auckland").format('a')}`}</span></span>
        )
    }
    const getTides = (date) => {
        const values = _.filter(data, (item) => moment.tz(item.timestamp, "Pacific/Auckland").isSame(moment.tz(date, "Pacific/Auckland"), 'day'));
        const mean = _.meanBy(values, (val) => val.y);
        return (
            <Row className='ml-0 mr-0 pl-0 pr-0'>
                {_.map(values, (val, index) => {

                    return (
                        <Col key={index} className='ml-0 mr-0 pl-0 pr-0' data-tip={`${val.y}m`}>
                            <ReactTooltip className='tideTooltip' backgroundColor='white' textColor='black' borderColor='lightblue' border/>
                            <Row className='ml-0 mr-0 pl-0 pr-0'>
                                <Col  className='ml-0 mr-0 pl-0 pr-0 tide-item'>
                                   {val.y > mean ?  getTideTime(true, val.timestamp, val.y): <br/>} 
                                </Col>
                            </Row>
                            <Row className='ml-0 mr-0 pl-0 pr-0'>
                                <Col  className='ml-0 mr-0 pl-0 pr-0 tide-item'>
                                    {val.y <= mean ?  getTideTime(false, val.timestamp, val.y): <br/>} 
                                </Col>
                            </Row>
                            {/* {getTideTime(val.y > mean , val.timestamp)} */}
                        </Col>
                    )
                })}
            </Row>
        )
    }
    const dates = data && _.uniq(_.map(data, (item) => moment.tz(item.timestamp, "Pacific/Auckland").startOf('day').format()));
    return (
        <div className='tidesContainer'>
            <Row className='ml-0 mr-0 pl-0 pr-0'>
                {_.map(dates, (date, index) => (
                        <Col key={index} className='ml-0 mr-0 pl-0 pr-0' style={{backgroundColor: index % 2 > 0 ? 'rgba(68, 170, 213, 0.1)' : 'rgba(0, 0, 0, 0)'}}>
                            {getTides(date)}
                        </Col>
                ))}
            </Row>
        </div>
    )
}

export default TidesChart;