import React from "react";
import { TiLocationArrow } from "react-icons/ti";
import { calculateRelativeDirection } from "tools/utills";

const DirectionArrow = ({ color, size, direction }) => (
  <div style={{ transform: `rotate(${calculateRelativeDirection(direction)}deg)` }}>
    {!direction ? (
      <span style={{color: color}}>-</span>
    ) : (
      <TiLocationArrow color={color} size={size} style={{ transform: `rotate(${-45}deg)` }} />
    )}
  </div>
);

export default DirectionArrow;
