import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card, CardTitle, CardBody} from 'reactstrap';
import _ from 'lodash';
import '../HomePostsSection.scss';
import ApiService from 'services/ApiService.js';
import moment from 'moment';
import PostCards from '../../../components/PostCards/PostCards';

const ForecastSection = ({
    onSelectPost
}) => {
    const [page, setPage] = useState(1);
    const [posts, setPosts] = useState([]);
    
    useEffect(() => {
        (async function callApi() {
          const posts = await ApiService.get(`posts?_limit=${(page*3)+1}&_sort=id:DESC&zone_eq=forecast`);
          setPosts(posts)
        })();
      },[page]);

  //   /**
  //  * render the other posts
  //  * @param {*} post 
  //  * @param {*} index 
  //  * @param {*} className 
  //  * @returns 
  //  */
  // const renderCard = (post, index, className) => (
  //   <Col xs={12} lg="4" md="6" key={index} className={`pl-0 pr-0 ${className}`}>
  //     <div className="post-card">
  //       <Card className="card-blog">
  //         <div className="card-image">
  //           <img
  //             alt="..."
  //             className="img rounded"
  //             src={post.thumbnail.url}
  //             onClick={() => onSelectPost(post)}
  //           ></img>
  //         </div>
  //         <CardBody>
  //           <h6 className="category text-warning">
  //             <i className="now-ui-icons business_bulb-63"></i> {post.category}
  //           </h6>
  //           <CardTitle tag="h5">
  //             <a
  //               href="#pablo"
  //               style={{ color: "#2c2c2c" }}
  //               onClick={(e) => {
  //                 onSelectPost(post);
  //                 e.preventDefault();
  //               }}
  //             >
  //               {post.title}
  //             </a>
  //           </CardTitle>
  //           <p className="card-description">{post.short_description}</p>
  //           <p className="post-date">
  //             {moment(post.created_at).format("MMM DD, YYYY")}
  //           </p>
  //         </CardBody>
  //       </Card>
  //     </div>
  //   </Col>
  // );
  
  /**
   * render the fist post in an image container 
   * only after medium size screen
   * @param {*} post 
   * @returns 
   */
  const renderMainPost = (post) => (
    <div className="main-post">
      <Row className="p-0 m-0">
        <Col className="p-0 m-0 d-none d-md-flex">
          <div className="main-post">
            <img
              alt="..."
              className="img rounded img-raised"
              src={post.thumbnail.url}
              onClick={() => onSelectPost(post)}
            ></img>
            <div className="main-post-overlay img-raised">
              <CardTitle tag="h3">
                <a
                  href="#pablo"
                  onClick={(e) => {
                    onSelectPost(post);
                    e.preventDefault();
                  }}
                >
                  {post.title}
                </a>
              </CardTitle>
              <p className="card-description">{post.short_description}</p>
              <p className="post-date">
                {moment(post.created_at).format("MMM DD, YYYY")}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
  return (
    <Container className="pl-0 pr-0">
      {posts && posts.length ? (
        <div className="forcast-home">
          {renderMainPost(posts[0])}
          <div className="home-other-posts">
            <Row>
              {_.map(posts, (post, index) =>
                // renderCard(post, index, !index ? "d-flex d-md-none" : "")
                <PostCards key={index} post={post} className={!index ? "d-flex d-md-none" : ""} onSelect={onSelectPost}/>
              )}
            </Row>
            {/* <div className="load-more" onClick={() => setPage(page + 1)}>
              <div>LOAD MORE</div>
            </div> */}
          </div>
        </div>
      ) : (
        <span />
      )}
    </Container>
  );
}

export default ForecastSection;