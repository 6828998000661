import React from "react";
import WindArrow from './WindArrow';
import {getDirectionLabel} from '../../tools/utills';
import "./WindIcon.scss";


const WindIcon = ({ speed, direction, textColor, iconColor }) => {

  return (
    <div className="wind-box">
      <WindArrow speed={speed} direction={direction} iconColor={iconColor} />
      <div style={{ color: textColor || "#FD2DBF" }} className="wind-speed">
        <span>{speed}</span>
      </div>
      <div style={{ color: textColor || "#FD2DBF", fontWeight: 'normal'}} className="wind-direction-label">
        <span>{getDirectionLabel(direction)}</span>
      </div>
    </div>
  );
};

export default WindIcon;
