import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import ApiService from "../../../services/ApiService";
import Description from '../../../components/Description/Description';

const HomeDescription = () => {
    const [description, setDescription] = useState(null);
    useEffect(() => {
        (async function callApi() {
           try {
            const results = await ApiService.get('home-description');
            setDescription(results && results.description);
           } catch(e) {
               console.log(e);
           }
        })();
    }, []);

    return (
        <>
            {description ?
                (<Container className="pl-0 pr-0">
                    <div className="forcast-home">
                        <div className="home-description">
                            <Description value={description} />
                        </div>
                    </div>
                </Container>) 
                : <span />
            }
        </>
    )
}

export default HomeDescription;