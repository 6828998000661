import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import api2Service from "../../../services/Api2Services";
import amplifyApiService from "services/AmplifyApiService";

export const getPackages = createAsyncThunk(
  "subscription/getPackages",
  async (params, { rejectWithValue }) => {
    try {
      // const results = await api2Service.get("payments/packages");
      const results = await amplifyApiService.get("payments/packages");
      if (results?.data) {
        return results.data.packages;
      } else {
        return [];
      }
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

const initialState = {
  info: {
    packages: [],
    selectedPackage: null,
  },
  error: null,
  state: "idle",
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    selectSubscription: (state, action) => {
      state.info.selectedPackage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPackages.fulfilled, (state, action) => {
      state.info.packages = action.payload;
      state.status = "succeeded";
      state.error = null;
    });
    builder.addCase(getPackages.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(getPackages.rejected, (state, action) => {
      state.error = action.payload;
      state.info.packages = [];
      state.info.selectedPackage = null;
      state.status = "failed";
    });
  },
});

export const selectSubscriptionInfo = (state) => state.subscription.info;
export const { selectSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;
