import React from "react";
import { CardTitle } from "reactstrap";
import "./Cam.scss";

const Cam = ({ resource, type }) => {
  return resource ? (
    <div className="cam">
      <a href={resource.link}>
        <img alt={resource.name} className="cam-img" src={resource.image && resource.image.url} target="_blank" />
      </a>
      <div className="cam-overlay img-raised">
        <CardTitle tag="h6" >
          <a href={resource.link}>
            <b className="cam-title">{resource.name}</b>
          </a>
        </CardTitle>
      </div>
    </div>
  ) : (
    <span />
  );
};

export default Cam;
