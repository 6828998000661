import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const CheckBox = ({
  label,
  placeholder,
  inputRef,
  name,
  content,
  invalid,
  error,
  ...props
}) => {
  const { ref, ...inputProps } = inputRef || { ref: null };
  return (
    <FormGroup>
      <Input
        style={{ marginLeft: "0 !important" }}
        type="checkbox"
        innerRef={ref}
        {...inputProps}
        {...props}
        invalid={invalid}
      />
      <Label style={{ paddingLeft: "20px" }}>{content}</Label>
      <FormFeedback>{error ? error : "something went wrong!"}</FormFeedback>
    </FormGroup>
  );
};

export default CheckBox;
