import React, {useEffect, useState} from "react";
import { Col, ListGroup, Container, Row } from "reactstrap";
import { useHistory, Link } from "react-router-dom";
import { MdClose } from "react-icons/md";
import _ from "lodash";
import "./LocationFooter.scss";
import logoMobile from "../../assets/logo/gsn_logo_mobile.png";
import niNorthEastCoast from "../../assets/coasts/niNorthEastCoast.png";
import niSouthEastCoast from "../../assets/coasts/niSouthEastCoast.png";
import niWestCoast from "../../assets/coasts/niWestCoast.png";
import siEastCoast from "../../assets/coasts/siEastCoast.png";
import siWestCoast from "../../assets/coasts/siWestCoast.png";
import forecastImage from "../../assets/nav/forecasts_white.png";
import get_pitted from "../../assets/banners/get_pitted.png";
import best_website from "../../assets/banners/best_website.png";
import west_label from "../../assets/labels/west.png";
import east_label from "../../assets/labels/east.png";
import constants from "../../constants";
import ApiService  from 'services/ApiService.js';
import liveCam from "../../assets/cams/cam_icon.png"

const LocationFooter = ({ locations, navbar, onClose }) => {
  const history = useHistory();
  const [banner, setBanner] = useState();
  
  useEffect(() => {
    async function getBanner () {
      const bnr = await ApiService.get('banner');
      setBanner(bnr);
    };
    if (navbar) {
      getBanner();
    }
  }, [navbar]);

  const handleClickLogo = () => {
    history.push({
      pathname: "/",
    });
  };
  const handleSelectLocation = (location) => {
    // should redirect to the location page with the id
    history.push({
      pathname: `/location/${location.slug}`,
      // search: '?query=abc',
      // state: { location }
    });
  };

  const getImage = (coast) => {
    switch (coast) {
      case constants.Coasts.NI_WEST_COAST:
        return niWestCoast;
      case constants.Coasts.NI_NORTH_EAST_COAST:
        return niNorthEastCoast;
      case constants.Coasts.NI_SOUTH_EAST_COAST:
        return niSouthEastCoast;
      case constants.Coasts.SI_WEST_COAST:
        return siWestCoast;
      case constants.Coasts.SI_EAST_COAST:
        return siEastCoast;
      default:
        return null;
    }
  };

  const renderLogo = (cs) => (
    <Col
      xs={navbar ? 6 : 6}
      sm={navbar ? 6 : 4}
      md={navbar ? 6 : 3}
      lg={navbar ? 6 : 2}
      className={`pl-0 pr-0 ml-0 mr-0 ${cs}`}
      style={{ paddingBottom: "20px"}}
    >
      <div className={navbar ? 'good-surf-logo-container' : 'good-surf-logo-container'}>
        <img src={logoMobile} alt="Good Surf Now" className="footer-image" onClick={handleClickLogo}></img>
      </div>
    </Col>
  );
  const renderName = (location, index) => (
    <div className="location-name">
        <Link
          key={index}
          to={`/location/${location?.slug}`}
          className={`location-text ${location.gap ? "location-gap" : ""}`}
        >
          {location.name} 
          {location.gsn_cameras?.length ? (
        <img
            src={liveCam}
            alt="live_cam"
            style={{ width: "16.5px", height: "10.125px", marginLeft: "5px"  }}
          ></img>
        ):(
          ""
        )}
        </Link>
    </div>
  );

  const renderCoast = (coast) => (
    <Col xs={navbar ? 6 : 6} sm={navbar ? 6 : 4} md={navbar ? 6 : 3} lg={navbar ? 6 : 2} className="pl-0 pr-0 ml-0 mr-0" key={coast}>
      <Row className="pl-0 pr-0 ml-0 mr-0">
        <Col className="pl-0 pr-0 ml-0 mr-0">
          <div className="coast-icons">
            <img src={getImage(coast)} alt="Good Surf Now" className="footer-image"></img>
          </div>
        </Col>
      </Row>
      <ListGroup className="card-columns">
        {_.map(
          _.filter(locations, (location) => location.coast === coast),
          (nLocation, index) => (
            <div key={index} className="location-text">
            {renderName(nLocation, index)}
            {nLocation.gap ? <div className="location-spacer"></div> : <div/>}
            </div>
          )
        )}
      </ListGroup>
    </Col>
  );

  return (
    <Container fluid className="pl-0 pr-0 section-location-list">
      <Container className="pl-0 pr-0">
        {navbar ? (
          <Row className="navbar-close">
            <Col className="m-0 d-flex justify-content-end align-items-center">
              <MdClose size={35} color="#FD2DBF" onClick={onClose} className="close-button"/>
            </Col>
          </Row>
        ) : (
          <Row />
        )}
        
        {!navbar ? <Row className='pl-0 pr-0 ml-0 mr-0 pt-0'>
          <Col className="forecast-image">
            <img alt="gsn-nav" className="location-img" src={forecastImage} style={{width:"150px", height: "25px"}} />
          </Col>
        </Row> : <span/>}
        <div className={`${navbar ? "navbar-scroll" : ""}`}>
          {navbar && banner && banner.image? (
            <Row className="pl-0 pr-0 ml-0 mr-0 pt-0">
              <Col className="m-0 banner-image">
                <img src={banner.image.url} alt="banner"/>
              </Col>
            </Row>
          ) : (
            <Row />
          )}
          {
            navbar ? (
              <Row className={`pl-0 pr-0 ml-0 mr-0 pt-5`}>
            <Col xs={6} className="pl-0 pr-0 ml-0 mr-0 coast-label">
              <img alt="west" className="coast-label-img" src={west_label}/>
            </Col>
            <Col xs={6} className="pl-0 pr-0 ml-0 mr-0 coast-label">
              <img alt="east" className="coast-label-img" src={east_label}/>
            </Col>
          </Row>
            ) : <Row/>
          }
          <Row className={`pl-0 pr-0 ml-0 mr-0 pt-4`}>
              {renderCoast(constants.Coasts.NI_WEST_COAST)}
              {renderCoast(constants.Coasts.NI_NORTH_EAST_COAST)}
              {navbar ? renderLogo(`d-flex justify-content-center  align-items-center`) : <span />}
              {!navbar ? renderLogo(`d-flex d-sm-none justify-content-center  align-items-center`) : <span />}
              {renderCoast(constants.Coasts.NI_SOUTH_EAST_COAST)}
              {renderCoast(constants.Coasts.SI_WEST_COAST)}
              {renderCoast(constants.Coasts.SI_EAST_COAST)}
              {!navbar ? <Col md={6} className="d-none d-md-flex d-lg-none" /> : <span />}
              {!navbar ? renderLogo(`d-none d-sm-flex d-md-flex d-lg-none justify-content-center align-items-end`) : <span />}
              {!navbar ? renderLogo(`d-none d-lg-flex justify-content-center align-items-start`) : <span />}
          </Row>
        </div>
        {!navbar ? 
        <Row className={`pl-0 pr-0 ml-0 mr-0 pt-5`}>
        <Col className="pl-0 pr-0 ml-0 mr-0" xs={12} md={6}>
          <div className='footer-banner-left'>
            <img src={best_website} alt="banner" style={{width: '350px', height: '117px'}}/>
          </div>
        </Col>
        <Col className="pl-0 pr-0 ml-0 mr-0" xs={12} md={6}>
          <div className='footer-banner-desc'>
              <h6 className="banner-desc-text">The NZ surf forecast toolbox - everything you need to score as many waves as possible: <br/><br />
                - The <a href="https://www.goodsurfnow.co.nz/post/wave_forecasting_and_modelling">most accurate</a> forecast data available <br/><br/>
                - Links to the free <a href="https://www.goodsurfnow.co.nz/post/how_to"> webcams and wave buoys</a> for every forecast <br/><br/>
                - <a href=" https://www.goodsurfnow.co.nz/post/regional_forecast">Regional forecasts</a> every Thursday and Sunday <br /><br/>
              And the latest surf videos and news from Owwwwtearoa and the world!
              </h6>
          </div>
          <div className='footer-banner-bottom'>
            <img src={get_pitted} alt="banner" style={{width: '275px', height: '64px'}}/>
          </div>
        </Col>
      </Row>
      : <div/>}
      </Container>
    </Container>
  );
};

export default LocationFooter;
