import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Col, Container } from "reactstrap";
import _ from "lodash";
import moment from "moment";
import "./ForcastCharts.scss";
import TidesChart from "./TidesChart";
import MetOceanCharts from "./MetOceanCharts";
import { fetchData } from "../../services/MetOceanDataService";
import legend from "../../assets/charts/legend.png";

const ForcastCharts = ({ location }) => {
  const [metOceanData, setMetOceanData] = useState(null);
  const history = useHistory();

  useEffect(() => {
    (async function callApi() {
      const data = await fetchData(location);
      // console.log(JSON.stringify(data));
      //   const cData = _.find(data, (item) => moment().isBefore(moment(item.timestamp)));
      setMetOceanData(data);
      //   setCurrentData(cData);
    })();
  }, [location]);

  const handleClickHowTo = () => {
    history.push("/post/how_to/");
  };
  return metOceanData ? (
    <Container fluid className="pl-0 pr-0">
      <div className="chart-headers">
        <Col xs={12} className="chart-7-day-cols">
          <div className="chart-7-day-header">
            {location ? <h4>{`${location.name} 7 day forecast`}</h4> : <span />}
          </div>
        </Col>
      </div>
      <div className="chart-container">
        <MetOceanCharts location={location} data={metOceanData} />
        <TidesChart location={location} />
      </div>
      <div className="chart-headers">
        <Col className="chart-cols">
          <div className="chart-label">
            <span className="chart-title">
              TIDE{" "}
              <sup>
                <span className="wave-chart-unit">HIGH </span>
              </sup>
              <span className="period-chart-unit">LOW</span>
            </span>
          </div>
        </Col>
      </div>
      <div className="chart-headers">
        <Col className="d-none d-sm-flex chart-cols">
          <div className="chart-legend">
            <img
              alt="chart legend"
              src={legend}
              className="legend-image-desktop"
            />
            <h6 className="chart-how-to" onClick={handleClickHowTo}>
              How to use forecast
            </h6>
          </div>
        </Col>
        <Col xs={12} className="d-flex d-sm-none chart-cols">
          <div className="chart-legend">
            <img
              alt="chart legend"
              src={legend}
              className="legend-image-mobile"
            />
            <h6 className="chart-how-to" onClick={handleClickHowTo}>
              How to use forecast
            </h6>
          </div>
        </Col>
      </div>
    </Container>
  ) : (
    <span />
  );
};

export default ForcastCharts;
