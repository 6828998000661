import _ from "lodash";
import Axios from "axios";
import moment from "moment-timezone";

const mergeData = (data) => {
  // console.log(JSON.stringify(data));
  if (data.variables) {
    const time =
      data &&
      data.dimensions &&
      data.dimensions.time &&
      data.dimensions.time.data;
    const seaSurfaceHeight = data.variables["wave.height"].data;
    const seaSurfaceHeight8s = data.variables["wave.height.above-8s"].data;
    const windSpeed = data.variables["wind.speed.at-10m"].data;
    const windDirection = data.variables["wind.direction.at-10m"].data;
    const wavePeriod = data.variables["wave.period.peak"].data;
    const wavePeriodB8s = data.variables["wave.period.below-8s.peak"].data;
    const wavePeriod8s = data.variables["wave.period.above-8s.peak"].data;
    const waveDirectionB8s =
      data.variables["wave.direction.below-8s.peak"].data;
    const waveDirection = data.variables["wave.direction.peak"].data;
    const waveDirection8s = data.variables["wave.direction.above-8s.peak"].data;

    return _.map(time, (slot, index) => {
      return {
        timestamp: slot,
        y: seaSurfaceHeight.length > index ? seaSurfaceHeight[index] : null,
        waveHeight8s:
          seaSurfaceHeight8s.length > index ? seaSurfaceHeight8s[index] : null,
        windSpeed: windSpeed.length > index ? windSpeed[index] * 1.944 : null,
        windDirection:
          windDirection.length > index ? windDirection[index] : null,
        wavePeriodB8s:
          wavePeriodB8s.length > index ? wavePeriodB8s[index] : null,
        wavePeriod: wavePeriod.length > index ? wavePeriod[index] : null,
        wavePeriod8s: wavePeriod8s.length > index ? wavePeriod8s[index] : null,
        waveDirectionB8s:
          waveDirectionB8s.length > index ? waveDirectionB8s[index] : null,
        waveDirection:
          waveDirection.length > index ? waveDirection[index] : null,
        waveDirection8s:
          waveDirection8s.length > index ? waveDirection8s[index] : null,
      };
    });
  } else {
    return null;
  }
};

const fetchData = async ({ id, latitude, longitude }) => {
  try {
    //get current dates 00:00 in local format
    const date = moment
      .tz("Pacific/Auckland")
      .startOf("date")
      .utc()
      .format("YYYY-MM-DDTHH:mm:ss");
    const results = await Axios.post(
      `https://10f5iarxy9.execute-api.ap-southeast-2.amazonaws.com/prod/metocean/${id}`,
      {
        location: id,
        latitude: _.trim(latitude),
        longitude: _.trim(longitude),
        date: date + "Z",
      },
      {
        headers: {
          "X-Api-Key": "ZdoncwT4zd2IIsftcLaQU6Bq7c3avkIv1Il36jG0",
        },
      }
    );
    // if (results.data && results.data.body) {
    //   return mergeData(
    //     typeof results.data.body === "string"
    //       ? JSON.parse(results.data.body)
    //       : results.data.body
    //   );
    // } else {
    //   return null;
    // }
    if (results?.data?.data?.response) {
      const data = typeof results?.data?.data?.response === "string"
      ? JSON.parse(results?.data?.data?.response)?.data 
      ? JSON.parse(results?.data?.data?.response)?.data
      : JSON.parse(results?.data?.data?.response)
      : results?.data?.data?.response
      return mergeData(data);
    } else {
      return null;
    }
  } catch (e) {
    console.log(e.message);
  }
};

export { fetchData };
