import React, { useState, useEffect } from 'react';
import { Col, Row, Container } from 'reactstrap';
import './AdSlot.scss';
import AdSpacer from 'components/common/AdSpacer';

const AdSlot = ({
    id,
    page,
    genericAds,
    bottomSpaced,
    topSpaced
}) => {
    const [slot, setSlot] = useState(null);

    useEffect(() => {
        if (page && page[`ad_slot${id}_image`] && !page[`ad_slot${id}_hide`]) {
            setSlot({
                image: page[`ad_slot${id}_image`] && page[`ad_slot${id}_image`].url,
                link: page[`ad_slot${id}_link`],
                hide: page[`ad_slot${id}_hide`]
            })
        } else if (genericAds && genericAds[`ad_slot${id}_image`] && !genericAds[`ad_slot${id}_hide`]) {
            setSlot({
                image: genericAds[`ad_slot${id}_image`].url,
                link: genericAds[`ad_slot${id}_link`],
                hide: genericAds[`ad_slot${id}_hide`]
            })
        } else {
            // google adslot
        }
    }, [id, page, genericAds]);

    const handleClick = (link) => {
        if (link) {
            window.location = link
        }
    }
    return slot && !slot.hide ? (
        <Container className="generic-ad-slot-container" >
            {topSpaced ? <AdSpacer /> : <span />}
            <Row>
                <Col className="ml-auto mr-auto" xs="12" md="8">
                    <div onClick={() => handleClick(slot.link)} className={`${slot.link ? 'generic-ad-slot generic-ad-slot-center' : 'generic-ad-slot-center'}`}>
                        <img alt="slot-1" className="generic-ad-slot" style={{ transition: 'none !important' }} src={slot.image} />
                    </div>
                </Col>
            </Row>
            {bottomSpaced ? <AdSpacer /> : <span />}
        </Container >
    ) :
        <div />
}

export default AdSlot;