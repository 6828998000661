import React from "react";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";

const TextInput = ({
  label,
  placeholder,
  type,
  inputRef,
  name,
  invalid,
  error,
  ...props
}) => {
  const { ref, ...inputProps } = inputRef || { ref: null };
  return (
    <FormGroup>
      <Label for={name}>{label}</Label>
      <Input
        placeholder={placeholder}
        type={type}
        innerRef={ref}
        name={name}
        {...inputProps}
        {...props}
        invalid={invalid}
      />
      <FormFeedback>{error ? error : "something went wrong!"}</FormFeedback>
    </FormGroup>
  );
};

export default TextInput;
