import MobileDetect from "mobile-detect";
import useShouldShowPrompt from "./useShouldShowPrompt";

const iosInstallPromptedAt = "iosInstallPromptedAt";

const md = new MobileDetect(window.navigator.userAgent);

const isIOS = () => {
  // @ts-ignore
  if (navigator.standalone) {
    //user has already installed the app
    return false;
  }
  return md.is("iPhone") || md.is("iPad");
};

const useIosInstallPrompt = () => {
  const [userShouldBePromptedToInstall, handleUserSeeingInstallPrompt] =
    useShouldShowPrompt(iosInstallPromptedAt);

  return [
    isIOS() && userShouldBePromptedToInstall,
    handleUserSeeingInstallPrompt,
  ];
};
export default useIosInstallPrompt;
