
import Axios from 'axios';

class ApiService {
    /**
   * execute an get api method after signng it with the aws credentials
   * @param {*} endpoint
   * @param {*} credentials
   */
  async get(endpoint) {
    const opts = {
      method: 'GET',
      url: `https://api.goodsurfnow.com/${endpoint}`,
      headers: {}
    };
    const results= await Axios(opts);
    return results && results.data;
  }
}

const apiService = new ApiService();
export default apiService;