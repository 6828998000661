import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col, Container, Form, Button, FormFeedback } from "reactstrap";
import "./ResetPasswordSection.scss";
import TextInput from "components/common/Form/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "redux/features/auth/authSlice";

const ResetPasswordSection = ({ data }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authInfo?.info);
  const error = useSelector((state) => state?.authInfo?.error);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
  });

  useEffect(() => {
    if (user?.email && !user?.pwdResetRequested) {
      history.push("/sign-in");
    }
  }, [user?.email, user?.pwdResetRequested]);

  const onSubmit = (data) => {
    dispatch(
      resetPassword({
        email: user?.email,
        code: data?.code,
        password: data?.password,
      })
    );
  };
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">Reset Password</h1>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                placeholder="code"
                label="code"
                type="code"
                inputRef={register("code", { required: true })}
                name="code"
                invalid={!!errors.code}
                error="This field code is required"
              />
              <TextInput
                placeholder="password"
                label="new password"
                type="password"
                inputRef={register("password", { required: true })}
                name="password"
                invalid={!!errors.password}
                error="This field password is required"
              />
              <TextInput
                placeholder="confirm password"
                label="confirm password"
                type="password"
                inputRef={register("confirmPassword", { required: true })}
                name="confirmPassword"
                invalid={!!errors.confirmPassword}
                error="This field confirm password is required"
              />
              {error?.message && (
                <span className="text-danger ml-2 mt-1 d-block">
                  {error?.message}
                </span>
              )}
              <Button block className="login-button">
                Verify{" "}
              </Button>
            </Form>
            <div className="extra-links-reset">
              <Button
                className="create-account"
                color="neutral"
                tag={Link}
                to="/sign-in"
              >
                go to sign in
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default ResetPasswordSection;
