import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import { DiscussionEmbed } from "disqus-react";
import { Container, Row, Col } from "reactstrap";
import HyvorTalk from "hyvor-talk-react";
import ReactMarkdown from "react-markdown";
import _ from "lodash";
// reactstrap components
import "./PostSections.scss";
import ApiService from "../../services/ApiService";
import PostCards from "../../components/PostCards/PostCards";
import Description from "../../components/Description/Description";
import SocialLinks from "../../components/SocialLinks/SocialLinks";
import constants from "../../constants";
import AdSlot from "../../components/Ads/AdSlot";
import { Helmet } from "react-helmet-async";

// core components

const PostSections = ({ slug }) => {
  const [post, setPost] = useState(null);
  const [related, setRelated] = useState([]);
  const [genericPostAds, setGenericPostAds] = useState(null);
  const history = useHistory();

  useEffect(() => {
    (async function callApi() {
      try {
        const psts = await ApiService.get(`posts?slug_eq=${slug}`);
        if (psts && psts.length) {
          if (psts[0].tags && psts[0].tags.length) {
            const query = `posts?${_.join(
              _.map(psts[0].tags, (tag) => `tags_in=${tag.id}`),
              "&"
            )}&id_ne=${psts[0].id}&_limit=${3}`;
            const rted = await ApiService.get(query);
            setRelated(rted);
          }
          setPost(psts[0]);
          const postGenericAds = await ApiService.get("post-content");
          setGenericPostAds(postGenericAds);
        }
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [slug]);

  const handleSelectPost = (post) => {
    // should redirect to the post page
    history.push({
      pathname: `/post/${post.slug}`,
      state: { post },
    });
  };

  return (
    <>
      {post ? (
        <>
          <Helmet>
            <title>{`${post.title} | Good Surf Now!`}</title>
            <meta
              property="og:title"
              key="og:title"
              content={`${post.title} | Good Surf Now!`}
            />
            <meta property="og:type" key="og:type" content="website" />
            <meta
              property="og:url"
              key="og:url"
              content={`https://www.goodsurfnow.co.nz/post/${slug}`}
            />
            <meta
              property="og:description"
              key="og:description"
              content={_.truncate(post.short_description, { length: 169 })}
            />
            <link
              rel="canonical"
              href={`https://www.goodsurfnow.co.nz/post/${slug}/`}
            />
            <meta
              name="description"
              content={`${_.truncate(post.short_description, { length: 169 })}`}
            />
          </Helmet>
          {post.banner ? (
            <div className="page-header page-header-small post-page-header">
              <div
                className="page-header-image"
                style={{
                  backgroundImage: `url(${post.banner.url})`,
                  zIndex: 1,
                }}
              ></div>
              <div className="content-center">
                {/* <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">{post.title}</h2>
                  <h4>{post.banner.url}</h4>
                </Col>
              </Row> */}
              </div>
            </div>
          ) : (
            <span />
          )}
          <div className="">
            <Container className="pl-0 pr-0">
              <div className="related-posts">
                <Row>
                  <Col className="ml-auto mr-auto" md="8">
                    <span className="text-start post-label">
                      {post.title} <br></br>
                      <small className="description text-start">
                        <ReactMarkdown>{post.short_description}</ReactMarkdown>
                      </small>
                    </span>
                    <div>
                      <AdSlot
                        id={constants.PostAdSlots.ONE}
                        page={post}
                        genericAds={genericPostAds}
                      />
                      {/* <AdSpacer /> */}
                    </div>
                    <Description value={post.long_description} parent={post} />
                    <div className="space-50"></div>
                    {_.map(post.images, (image, index) => (
                      <div className="card-image" key={index}>
                        <img
                          alt="..."
                          className="post-page-img"
                          src={image.url}
                        />
                        <div className="space-10"></div>
                      </div>
                    ))}
                    {/* <div className="space-50"></div>
              {post && post.video ?  <VideoBox link={post.video}/> : <div />} */}
                  </Col>
                </Row>
                <SocialLinks />
              </div>
            </Container>
            <div>
              <AdSlot
                id={constants.PostAdSlots.ONE}
                page={post}
                genericAds={genericPostAds}
              />
              {/* <AdSpacer /> */}
            </div>
            <Container className="pl-0 pr-0">
              <div className="related-posts">
                {/* <DiscussionEmbed
                  shortname="www-goodsurfnow-com"
                  config={{
                    url: `https://www.goodsurfnow.com/post/${slug}/`,
                    identifier: slug,
                    title: "haha",
                    language: "en_NZ", //e.g. for Traditional Chinese (Taiwan)
                  }}
                /> */}
                <HyvorTalk.Embed
                  websiteId="5319"
                  id={slug}
                  url={`https://www.goodsurfnow.co.nz/post/${slug}/`}
                  loadMode="Load More"
                />
              </div>
            </Container>
            {related && related.length ? (
              <Container className="pl-0 pr-0">
                <div className="related-posts">
                  <Row>
                    <Col className="pl-0 pr-0">
                      <h2 className="text-center title">
                        related posts<br></br>
                      </h2>
                    </Col>
                  </Row>
                  <Row>
                    {_.map(related, (post, index) => (
                      <PostCards
                        key={index}
                        post={post}
                        onSelect={handleSelectPost}
                      />
                    ))}
                  </Row>
                </div>
              </Container>
            ) : (
              <span />
            )}
          </div>
        </>
      ) : (
        <span />
      )}
    </>
  );
};

export default PostSections;
