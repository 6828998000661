import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import userReducer from "./features/user/userSlice";
import subscriptionReducer from "./features/subscription/subscriptionSlice";
import cameraReducer from "./features/camera/cameraSlice";

import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
import { thunk } from "redux-thunk";

const reducers = combineReducers({
  authInfo: authReducer,
  user: userReducer,
  subscription: subscriptionReducer,
  camera: cameraReducer,
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

// export default configureStore({
//   reducer: {
//     authInfo: authReducer,
//     user: userReducer,
//     subscription: subscriptionReducer,
//   },
// });
