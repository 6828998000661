import { Auth, Hub } from "aws-amplify";

class UserService {
  /**
   * register user
   * @param {*} email
   * @param {*} password
   */
  async register(email, password, firstName, lastName) {
    try {
      const response = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email, // optional
          "custom:gsnUserId": "id",
          "custom:firstName": firstName,
          "custom:lastName": lastName,
          // other custom attributes
        },
        autoSignIn: {
          enabled: true,
        },
      });
      return response?.user;
    } catch (error) {
      throw error;
    }
  }

  /**
   * send email confirmation
   * @param {*} username
   * @param {*} code
   */
  async confirm(username, code) {
    try {
      await Auth.confirmSignUp(username, code);
    } catch (error) {
      throw error;
    }
  }

  /**
   * resend confirmation code with amlify
   * @param {*} username
   */
  async resendConfirmationCode(username) {
    try {
      await Auth.resendSignUp(username);
      console.log("code resent successfully");
    } catch (error) {
      throw error;
    }
  }

  /**
   * forgot password code send
   * @param {*} username
   */
  async forgotPassword(username) {
    try {
      const result = await Auth.forgotPassword(username);
      return result;
    } catch (error) {
      throw error;
    }
  }

  /**
   * new password submition
   * @param {*} username
   * @param {*} code
   * @param {*} new_password
   */
  async forgotPasswordSubmit(username, code, new_password) {
    try {
      const result = await Auth.forgotPasswordSubmit(
        username,
        code,
        new_password
      );
      return result;
    } catch (error) {
      throw error;
    }
  }
}

const userService = new UserService();

export default userService;
