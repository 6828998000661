import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import _ from "lodash";
import { renderToString } from "react-dom/server";
import moment from "moment-timezone";
import $ from "jquery";
import SpeedDirectionTrans from "./SpeedDirectionTrans";
import "./WaveHeightChart.scss";
import Tooltip from "./Tooltip";

class WaveHeightChart extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.chart = React.createRef();
  }

  addSymbol = (chart) => {
    $(".speedDirection").remove();
    // chart.chartBackground.attr({
    //     width: '100%'
    //   });
    _.each(chart.series, (serie) => {
      _.each(serie.data, (p, i) => {
        const image = renderToString(
          <div style={{ height: 32, width: 32, display: "flex", justifyContent: "center", alignItems: "center" }}>
            <SpeedDirectionTrans
              speed={p && p.wavePeriod && p.wavePeriod.toFixed(0)}
              direction={p && p.waveDirection && p.waveDirection.toFixed(0)}
            />
          </div>
        );
        const x = chart.renderer
          .text(image, p.barX + chart.plotLeft + chart.series[0].barW / 2 - 15, chart.chartHeight - 25, true)
          .css({
            opacity: 1,
            // 'background-color':'red'
          })
          .add();
        x.addClass("speedDirection");
      });
    });
  };

  getArrow(point) {
    const { waveDirection, waveDirection8s, windDirection, windSpeed, wavePeriod, wavePeriod8s, waveHeight, waveHeight8s, wavePeriodB8s, waveDirectionB8s, timestamp } = point;
    return renderToString(
      <Tooltip
        waveDirection={waveDirection}
        wavePeriod={wavePeriod}
        windDirection={windDirection}
        windSpeed={windSpeed}
        waveHeight={waveHeight}
        waveHeight8s={waveHeight8s}
        timestamp={timestamp}
        waveDirection8s={waveDirection8s}
        wavePeriod8s={wavePeriod8s}
        wavePeriodB8s={wavePeriodB8s}
        waveDirectionB8s={waveDirectionB8s}
      />
    );
  }
  getPlotLines = (data) => {
    const lines = _.map(data, (slot, index) => ({
      color: "lightgray", // Color value
      // dashStyle: 'longdashdot', // Style of the plot line. Default to solid
      value: index + 0.5, // Value of where the line will appear
      width: 1, // Width of the line
    }));
    lines.unshift({
      color: "lightgray", // Color value
      value: -0.5, // Value of where the line will appear
      width: 1, // Width of the line
    });
    return lines;
  };
  calculateMaxY(data) {
    const waveHeight = _.map(data, (item) => item.y);
    const max = _.max(waveHeight);
    return max && max <= 2 ? 2.5 : max <= 3 ? 3.5 : max + 0.5;
  }
  getFilteredData(data, hour, top) {
    return _.compact(
      _.map(data, (item) => {
        const h = moment.tz(item.timestamp, "Pacific/Auckland").hour();
        if (h === hour || (h === (hour === 0 ? 23 : hour - 1)) || h === hour + 1) {
          return {
            y: top ? item.y - item.waveHeight8s : item.y - (item.y - item.waveHeight8s),
            timestamp: item.timestamp,
            windSpeed: item.windSpeed,
            windDirection: item.windDirection,
            wavePeriodB8s: item.wavePeriodB8s,
            wavePeriod: item.wavePeriod,
            wavePeriod8s: item.wavePeriod8s,
            waveDirectionB8s: item.waveDirectionB8s,
            waveDirection: item.waveDirection,
            waveDirection8s: item.waveDirection8s,
            waveHeight: item.y,
            waveHeight8s: item.waveHeight8s,
          };
        } else {
          return null;
        }
      })
    );
  }
  componentDidUpdate(prevProps) {
    const { location } = prevProps;
    const newLocation = this.props.location;
    if (location && newLocation) {
      if (location.id !== newLocation.id) {
        this.setOptions();
      }
    }
  }

  componentDidMount() {
    this.setOptions();
  }
  setOptions() {
    const { data } = this.props;
    if (data) {
      const THIS = this;
      const options = {
        chart: {
          //   width:1100,
          height: 280,
          type: "column",
          useHTML: true,
          marginBottom: 60,
          events: {
            load: function () {
              THIS.addSymbol(this);
            },
            redraw: function () {
              THIS.addSymbol(this);
            },
          },
          //need to stop this behaviour
          // scrollablePlotArea: {
          //     minWidth: 1000,
          //     scrollPositionX: 1
          // },
        },
        title: {
          text: "",
          style: {
            color: "#55555",
            fontWeight: "bold",
          },
        },
        //   subtitle: {
        //       text: 'Source: <a href="https://www.metocean.co.nz/">Metocean</a>'
        //   },
        xAxis: [
          {
            categories: _.uniq(_.map(data, (item) => moment.tz(item.timestamp, "Pacific/Auckland").format("ddd Do MMM"))),
            opposite: true,
            lineColor: "transparent",
            //   plotLines:this.getPlotLines(data),
            alternateGridColor: "rgba(68, 170, 213, 0.1)",
            labels: {
              style: {
                fontSize: 0,
                fontWeight: "bold",
                fontFamily: "Montserrat, Helvetica Neue, Arial, sans-seriff",
                textAlign: "center",
              },
            },
          },
          // {
          //     visible:true
          // }
        ],
        yAxis: {
          min: 0,
          endOnTick: false,
          max: this.calculateMaxY(data),
          gridLineColor: "transparent",
          visible: true,
          width: 0,
          labels: {
            enabled: false,
          },
          title: {
            text: "Height m",
            enabled: false,
          },
          stackLabels: {
            enabled: true,
            style: {
              color: "#231F20",
              fontWeight: 500,
              fontSize: 14,
              fontFamily: "Montserrat, Helvetica Neue, Arial, sans-seriff",
              textAlign: "center",
              textOutline: 0
            },
            formatter: function () {
              return this.total && this.total.toFixed(1);
            },
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          // pointFormat: `Sea height: <b>{point.y} m${this.getCrazyValue()}</b>`
          useHTML: true,
          formatter: function () {
            return THIS.getArrow(this.point);
          },
          borderRadius: 10,
          width: 300,
          shared: false,
          // crosshairs: true,
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            name: "12am",
            data: this.getFilteredData(data, 0, true),
            color: "#2196F3",
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                color: "#2196F3",
              },
            },
            stack: "12am",
          },
          {
            name: "12am",
            data: this.getFilteredData(data, 0, false),
            color: "#1A237E",
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                color: "#2196F3",
              },
            },
            stack: "12am",
          },
          {
            name: "6am",
            data: this.getFilteredData(data, 6, true),
            color: "#2196F3",
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                color: "#2196F3",
              },
            },
            stack: "6am",
          },
          {
            name: "6am",
            data: this.getFilteredData(data, 6, false),
            color: "#1A237E",
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                color: "#2196F3",
              },
            },
            stack: "6am",
          },
          {
            name: "12pm",
            data: this.getFilteredData(data, 12, true),
            color: "#2196F3",
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                color: "#2196F3",
              },
            },
            stack: "12pm",
          },
          {
            name: "12pm",
            data: this.getFilteredData(data, 12, false),
            color: "#1A237E",
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                color: "#2196F3",
              },
            },
            stack: "12pm",
          },
          {
            name: "6pm",
            data: this.getFilteredData(data, 18, true),
            color: "#2196F3",
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                color: "#2196F3",
              },
            },
            stack: "6pm",
          },
          {
            name: "6pm",
            data: this.getFilteredData(data, 18, false),
            color: "#1A237E",
            dataLabels: {
              enabled: false,
            },
            states: {
              hover: {
                color: "#2196F3",
              },
            },
            stack: "6pm",
          },
        ],
        plotOptions: {
          column: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "none",
            },
          },
          series: {
            groupPadding: 0,
            borderWidth: 0,
            events: {
              hide: function () {
                $(".speedDirection").remove();
              },
              show: function () {
                THIS.addSymbol(this.chart);
              },
            },
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        },
      };
      this.setState({ options });
    }
  }
  onChartAvailable(chartt) {
    // const data = _.map(chartt.userOptions.series, serie=> serie.data);
    // chartt.renderer.image('https://www.highcharts.com/samples/graphics/sun.png', 50, 50, 100, 30)
    // .add();
  }
  render() {
    const { options } = this.state;
    return (
      <div className="wave-height-chart">
        {/* <Container fluid>
                    <Row>
                        <Col>
                           <span className='chartTitle'>WAVE HEIGHT</span>
                        </Col>
                    </Row>
                </Container> */}
        {options ? (
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={this.chart}
            callback={this.onChartAvailable}
            // allowChartUpdate= {true}
            immutable={true}
          />
        ) : (
          <span />
        )}
      </div>
    );
  }
}

export default WaveHeightChart;
