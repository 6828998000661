/*eslint-disable*/
import React from "react";
import { useHistory } from "react-router-dom";
// reactstrap components
import { Container, Button } from "reactstrap";
import "./FooterBlack.scss";
// core components

const FooterBlack = () => {
  const history = useHistory();

  const handleClick = (slug) => {
    history.push({
      pathname: `/${slug}`
    })
  }
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container className='footer-container'>
          <nav>
            <ul>
              <li>
                <h6 className="footer-link-button" onClick={() => handleClick('about-us')}>About Us</h6>
              </li>
              <li>
                <h6 className="footer-link-button" onClick={() => handleClick('contact-us')}>Contact</h6>
              </li>
              <li>
                <h6 className="footer-link-button" onClick={() => handleClick('privacy-policy')}>Privacy Policy</h6>
              </li>
              <li>
                <h6 className="footer-link-button" onClick={() => handleClick('terms-and-conditions')}>Terms and Conditions</h6>
              </li>
            </ul>
          </nav>
          <div>
            <ul className="social-buttons pull-right">
              <li>
                <Button
                  className="btn-icon"
                  color="link"
                  href="https://www.youtube.com/channel/UCjyask-G-sWy9AWxShkcymw"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </Button>
              </li>
              <li>
                <Button
                  className="btn-icon"
                  color="link"
                  href="https://www.facebook.com/goodsurfnow"
                  target="_blank"
                >
                  <i className="fab fa-facebook-square"></i>
                </Button>
              </li>
              <li>
                <Button
                  className="btn-icon"
                  color="link"
                  href="https://www.instagram.com/goodsurfnow_nz/?hl=en"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </Button>
              </li>
            </ul>
          </div>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, {" "}
            <a href="https://www.goodsurfnow.co.nz" target="_blank">
              Good Surf Now Limited
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
};

export default FooterBlack;
