import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import amplifyApiService from "services/AmplifyApiService";

export const getUser = createAsyncThunk(
  "user/getUser",
  async (params, { getState, rejectWithValue }) => {
    try {
      const results = await amplifyApiService.get(`users/${params}`);
      if (results?.data) {
        return results.data.user;
      } else {
        return [];
      }
    } catch (err) {
      return rejectWithValue({
        code: err.code,
        message: err.message,
      });
    }
  }
);

const initialState = {
  info: null,
  error: null,
  state: "idle",
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    invalidateUser: (state) => {
      state.info = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.info = action.payload;
      state.status = "succeeded";
    });
    builder.addCase(getUser.pending, (state, action) => {
      state.status = "pending";
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.error = action.payload;
      state.status = "failed";
      state.user = null;
    });
  },
});

export const { invalidateUser } = userSlice.actions;
export const selectUserInfo = (state) => state.user.info;
export default userSlice.reducer;
