import React from "react";
import { TiLocationArrow } from "react-icons/ti";
import "./SpeedDirectionTrans.scss";

const calculateOpacity = (speed) => {
  if (speed < 5) {
    return 0.2;
  } else if (speed < 8) {
    return 0.3;
  } else if (speed < 11) {
    return 0.5;
  } else if (speed < 15) {
    return 0.7;
  } else if (speed < 19) {
    return 0.9;
  } else {
    return 1;
  }
};

const SpeedDirectionTrans = ({ direction, speed }) => {
  return (
    <div
      className="speed-direction-trans"
      style={{
        transform: `rotate(${direction ? parseInt(direction) : 0}deg)`,
      }}
    >
      <div
        id="badge-ribbon"
        style={{
          opacity: calculateOpacity(speed),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 15,
          fontWeight: "bold",
          border: "1px solid #1A237E",
          color: "#1A237E",
        }}
      >
        <span style={{ transform: `rotate(-${direction ? parseInt(direction) : 0}deg)`, opacity: "1 !important" }}>{speed}<span className="wave-period-label">s</span></span>
      </div>
      <div className="speed-direction-arrow" style={{ opacity: calculateOpacity(speed) }}>
        <TiLocationArrow color="#1A237E" size={20} style={{ transform: `rotate(${135}deg)` }} />
      </div>
    </div>
  );
};

export default SpeedDirectionTrans;
