import Axios from 'axios';
import moment from 'moment-timezone';

const fetchData =async ({id, latitude, longitude})=>{
    try{
        const date = moment.tz('Pacific/Auckland').format('YYYY-MM-DD');
        const results = await Axios.post(`https://10f5iarxy9.execute-api.ap-southeast-2.amazonaws.com/prod/niwa`,{
            location: id,
            latitude,
            longitude,
            date
        },{headers:{
            'X-Api-Key': 'ZdoncwT4zd2IIsftcLaQU6Bq7c3avkIv1Il36jG0'
          }}
        );
        if(results.data && results.data.body){
            return typeof results.data.body === 'string' ? JSON.parse(results.data.body) : results.data.body;
        }
        else{
            return null;
        }
        // const results = await Axios.get(`https://api.niwa.co.nz/tides/data?lat=${latitude}&long=${longitude}&numberOfDays=7&startDate=${date}&apikey=kA0aCBJI75CknWjMMU241p1CGhZVazS4`,{
        //     headers:{
        //         'x-apikey': 'kA0aCBJI75CknWjMMU241p1CGhZVazS4',
        //         'Accept': 'application/json'
        //       }
        // });

        // if(results.data){
        //     const data =  _.map(results.data.values , item => ({y: item.value, timestamp: item.time}));
        //     return data;
        // }
        // else{
        //     return null;
        // }
    }catch (e){
        console.log(e.message);
    }
} 

export {
    fetchData
}