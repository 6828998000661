import React, { useEffect, useState, useRef, useCallback } from "react";
import { Container, Row, Col } from "reactstrap";
import "./WindyWidget.scss";

const WindyWidget = ({ location }) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const containerRef = useRef();

  useEffect(() => {
    function handleResize() {
      setWidth(containerRef.current.clientWidth);
      let height = (width / 16) * 9;
      setHeight(height);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [width]);

  return (
    <Container className="windy-map-widget">
      <Row>
        <Col className="ml-auto mr-auto" md="8">
          <div ref={containerRef}>
            <iframe
              id="windy-widget"
              title="windy"
              key="windy"
              width={width?.toString()}
              height={height?.toString()}
              src={`https://embed.windy.com/embed2.html?lat=${location.latitude}&lon=${location.longitude}&detailLat=${location.latitude}&detailLon=${location.longitude}&width=650&height=450&zoom=5&level=surface&overlay=wind&product=ecmwf&menu=&message=&marker=true&calendar=now&pressure=&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1`}
              frameBorder="0"
            ></iframe>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default WindyWidget;
