import React from 'react';
import _ from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';
import VideoPlayer from '../video/VideoPlayer';
import ReactMarkdown from "react-markdown";
import './Description.scss';
import AdSlot from '../Ads/AdSlot';

const Description = ({
    value,
    parent
}) => {
    const parts = value ? value.split("\n") : [];

    return (
      <div className="description long-description">
        {_.map(parts, (part, index) => {
          if (part.startsWith("<image>")) {
            const link = part.replace("<image>", "");
            return (
              <div key={index}>
                <div className="space-50"></div>
                  <img alt="..." className="post-page-img" src={link} />
                <div className="space-50"></div>
              </div>
            );
          } else if (part.startsWith("<adslot>")) {
            const id = part.replace("<adslot>", "");
            return (
              <div key={index}>
                <div className="space-30"></div>
                  <AdSlot key={index} id={id} page={parent}/>
                <div className="space-50"></div>
              </div>
            )
          } else if (part.startsWith("<video>")) {
            const link = part.replace("<video>", "");
            return (
              <div key={index}>
                <div className="space-30"></div>
                  <VideoPlayer key={index} link={link} />
                <div className="space-50"></div>
              </div>
            );
          } else if (part.startsWith("<jumplink>")) {

            const link = part.replace("<jumplink>", "");

            try {
              const val = JSON.parse(link);
              return <Link smooth to={`#${val.id}`} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -64) }} className='gsn-hash-link'>{val.text}</Link>
            } catch (e) {
              console.log(e.message);
              return <p>{link}</p>;
            }
          } else if (part.startsWith("<jumper>")) {

            const link = part.replace("<jumper>", "");


            try {
              const val = JSON.parse(link);
              return <span id={`${val.id}`}/>
            } catch (e) {
              console.log(e.message);
              return <p>{link}</p>;
            }
          }else if (!part){
            return <ReactMarkdown key={index}>&nbsp;</ReactMarkdown>
          } else {
            return <ReactMarkdown key={index}>{part}</ReactMarkdown>
          }
        })}
      </div>
    );
}

export default Description;