import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "reactstrap";
import _ from "lodash";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import "./CheckoutSection.scss";
import { useSelector } from "react-redux";
import amplifyApiService from "services/AmplifyApiService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CenterSpinner from "components/Spinner/CenterSpinner";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const CheckoutSection = ({ data }) => {
  const [clientSecret, setClientSecret] = useState("");
  const user = useSelector((state) => state.authInfo.info);
  const subInfo = useSelector((state) => state.subscription.info);

  useEffect(() => {
    if (subInfo?.selectedPackage) {
      const pac = _.find(
        subInfo.packages,
        (_pac) => _pac.id === subInfo.selectedPackage
      );
      if (pac) {
        const callApi = async () => {
          const results = await amplifyApiService.post("payments", {
            priceId: pac.priceId,
            email: user?.email,
          });
          if (results) {
            setClientSecret(results?.data?.clientSecret);
          }
        };

        callApi().catch((err) => {
          toast.warn("Already subscribed", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: "Bounce",
          });
        });
      }
    } else {
      toast.warn("Something went wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: "Bounce",
      });
    }
    // window.location.replace(results?.data?.data?.paymentUrl);
  }, [subInfo?.selectedPackage]);
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">Checkout</h1>
          </Col>
        </Row>
        {clientSecret ? (
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <div id="checkout">
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{ clientSecret }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              </div>
            </Col>
          </Row>
        ) : (
          <div style={{ height: "396.91px" }}>
            <CenterSpinner type="border" size="lg" />
          </div>
        )}
      </Container>
    </div>
  );
};
export default CheckoutSection;
