import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import Cam from './Cam';
import constants from '../../constants';
import './CamContainer.scss';

const CamContainer = ({
    cam_1,
    cam_2,
    buoy
}) => {
    return (
        <Container className="pl-0 pr-0 cam-container">
            <Row className="p-0 m-0">
                <Col xs={12} sm={6} md={4} className="p-0 m-0 cam-box">
                    <Cam  resource={cam_1} type={constants.ResourceTypes.CAM}/>
                </Col>
                <Col xs={12} sm={6} md={4} className="p-0 m-0 cam-box">
                    <Cam  resource={cam_2} type={constants.ResourceTypes.CAM}/>             
                </Col>
                <Col xs={12} sm={6} md={4} className="p-0 m-0 cam-box">
                    <Cam  resource={buoy} type={constants.ResourceTypes.BUOY}/>
                </Col>
            </Row>
        </Container>
    )
}

export default CamContainer;