import React from "react";
import { useHistory } from "react-router-dom";
import logoDesktop from "../../assets/logo/gsn_logo_desktop.png";
// import navIcon from '../../assets/nav/nav_icon.png';
import navIcon from "../../assets/nav/forecast_burger_icon.png";
// import { FaBars } from 'react-icons/fa';
import LocationFooter from "../Locations/LocationFooter";
import navIconMobile from "../../assets/nav/nav_icon.png";
import "./GoodSurfNavBar.scss";

// reactstrap components
import { Collapse, Col, Row, Navbar, Container } from "reactstrap";

const GoodSurfNavBar = ({ locations }) => {
  const history = useHistory();
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  const handleClickLogo = () => {
    history.push({
      pathname: "/",
    });
  };

  const handleToggle = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  return (
    <>
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container className="pl-0 pr-0">
          <Row className="m-0 p-0 navbar-translate good-surf-nav-bar">
            <Col xs="auto" className="pl-0 pr-0 ml-0 mr-0 desktop-icon">
              <img
                src={logoDesktop}
                alt="Good Surf Now"
                style={{ width: "146px", height: "30px" }}
                onClick={handleClickLogo}
              ></img>
            </Col>
            <Col />
            <Col xs="auto" className="p-0 d-none d-sm-block">
              {/* <FaBars color="purple" size="30" onClick={handleToggle} className="good-surf-menu"/> */}
              <div className="gsn-nav-icon" onClick={handleToggle}>
                <img
                  alt="gsn-nav"
                  className="location-img"
                  src={navIcon}
                  style={{ width: "150px", height: "25px" }}
                />
              </div>
            </Col>
            <Col xs="auto" className="p-0 d-block d-sm-none">
              {/* <FaBars color="purple" size="30" onClick={handleToggle} className="good-surf-menu"/> */}
              <div className="gsn-nav-icon" onClick={handleToggle}>
                <img
                  alt="gsn-nav"
                  className="location-img"
                  src={navIconMobile}
                  style={{ width: "30px", height: "25px" }}
                />
              </div>
            </Col>
          </Row>
          <Collapse isOpen={collapseOpen} navbar>
            <LocationFooter
              locations={locations}
              navbar
              onClose={handleToggle}
            />
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default GoodSurfNavBar;
