import React from 'react';
import WaveHeightChart from './WaveHeightChart';
import WindChart from './WindChart';
import './MetOceanCharts.scss';

const MetOceanCharts = ({location, data}) => (
  <div>
    {
      data ? 
        <div>
            <WindChart location={location} data={data} />
            <WaveHeightChart location={location} data={data} />
            {/* <WindSpeedChart location={location} data={data} /> */}
        </div> : <div />
    }
  </div>
)

export default MetOceanCharts;