import React from "react";
import { Spinner } from "reactstrap";
import "./CenterSpinner.scss";

const CenterSpinner = ({ size, type }) => {
  return (
    <div className="spinner-page-center">
      <Spinner size={size || "sm"} type={type || "grow"} />
    </div>
  );
};

export default CenterSpinner;
