import React from 'react';
import { Col, Row } from 'reactstrap';
import './LocationAdSlotsTop.scss';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const LocationAdSlotsTop = ({
    slot1,
    slot2,
    slot3,
    showClass
}) => {
    const { width } = useWindowDimensions();

    const getClass = () => {
        if (slot2.hide && slot3.hide) {
            return 'location-ad-slot-top-large';
        }
        return 'location-ad-slot-top-small';
    }
    const handleClick = (link) => {
        if (link) {
            window.location = link
        }
    }
    if (width < 768) {
        // display for tabs and mobile
        return slot1.img || slot2.img || slot3.img ? (
            <Col xs={12} className={`p-0 m-0 ${showClass} location-ad-center`}>
                <div>
                    {slot1 && slot1.img && (
                        <Row className="p-0 m-0">
                            <Col className="p-0 m-0">
                                {/* <div onClick={() => handleClick(slot1.link)} className={`${slot1.link ? 'location-ad-slot-top' : ''} `}> */}
                                <img alt="slot-1" onClick={() => handleClick(slot1.link)} className={`${getClass()}-mobile ${slot1.link ? 'location-ad-slot-top' : ''}`} src={slot1.img} />
                                {/* </div> */}
                            </Col>
                        </Row>)}
                    {slot2 && slot2.img && !slot2.hide && (<Row className="p-0 m-0">
                        <Col className="p-0 m-0">
                            {/* <div onClick={() => handleClick(slot2.link)} className={`${slot2.link ? 'location-ad-slot-top' : ''} `}> */}
                            <img alt="slot-2" onClick={() => handleClick(slot1.link)} className={`${getClass()}-mobile ${slot1.link ? 'location-ad-slot-top' : ''}`} src={slot1.img} />
                            {/* </div> */}
                        </Col>
                    </Row>)}
                    {slot3 && slot3.img && !slot3.hide && (<Row className="p-0 m-0">
                        <Col className="p-0 m-0">
                            {/* <div onClick={() => handleClick(slot3.link)} className={`${slot3.link ? 'location-ad-slot-top' : ''} `}> */}
                            <img alt="slot-3" onClick={() => handleClick(slot1.link)} className={`${getClass()}-mobile ${slot1.link ? 'location-ad-slot-top' : ''}`} src={slot1.img} />
                            {/* </div> */}
                        </Col>
                    </Row>)}
                </div>
            </Col>
        ) : <div />
    } else {
        return slot1.img || slot2.img || slot3.img ? (
            <Col xs={12} md={6} className={`p-0 m-0 ${showClass} location-ad-center`}>
                <div className='loaction-ad-slots-top-contaner'>
                    {slot1 && slot1.img && (<Row className="p-0 m-0">
                        <div onClick={() => handleClick(slot1.link)} className={`${slot1.link ? 'location-ad-slot-top' : ''} `}>
                            <img alt="slot-1" className={getClass()} src={slot1.img} />
                        </div>
                    </Row>)}
                    {slot2 && slot2.img && !slot2.hide && (<Row className="p-0 m-0">
                        <div onClick={() => handleClick(slot2.link)} className={`${slot2.link ? 'location-ad-slot-top' : ''} `}>
                            <img alt="slot-2" className={getClass()} src={slot2.img} />
                        </div>
                    </Row>)}
                    {slot3 && slot3.img && !slot3.hide && (<Row className="p-0 m-0">
                        <div onClick={() => handleClick(slot3.link)} className={`${slot3.link ? 'location-ad-slot-top' : ''} `}>
                            <img alt="slot-2" className={getClass()} src={slot2.img} />
                        </div>
                    </Row>)}
                </div>
            </Col>
        ) : <div />
    }
}

export default LocationAdSlotsTop;