import constants from "../constants";

const calculateRelativeDirection = (direction) => {
  if (direction < 180) return direction + 180;
  else return direction - 180;
};

const calculateWindRelativeSpeed = (speed) => {
  if (speed < 5) {
    return 10;
  } else if (speed < 10) {
    return 15;
  } else if (speed < 15) {
    return 20;
  } else if (speed < 20) {
    return 25;
  } else if (speed < 30) {
    return 30;
  } else if (speed < 40) {
    return 35;
  } else {
    return 40;
  }
};

const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];

const getDirectionLabel = (heading) => {
  return heading
    ? directions[
        Math.round(((heading %= 360) < 0 ? heading + 360 : heading) / 45) % 8
      ]
    : "-";
};

const getSubscriptionPosition = (user) => {
  if (!user) {
    // user is not there
    return constants.SubscriptionPosition.YEARLY;
  } else {
    if (user?.blocked) {
      // user is blocked by the admin
      return constants.SubscriptionPosition.BLOCKED;
    } else {
      // user is there
      if (!user?.user_subscription) {
        // subscription is not there
        return constants.SubscriptionPosition.UNSUBSCRIBED;
      } else {
        // there is a subscription
        if (user?.status === constants.SubscriptionStatus.INACTIVE) {
          // subscription was expired
          return constants.SubscriptionPosition.EXPIRED;
        } else {
          // active subscription found and active users
          if (user.trial) {
            // user is still in the trial
            return constants.SubscriptionPosition.TRIAL;
          } else {
            // user not in trial
            if (
              user?.billing_period_unit === constants.BillingPeriodUnit.MONTH
            ) {
              // monthly subscription
              return constants.SubscriptionPosition.MONTHLY;
            } else if (
              user?.billing_period_unit === constants.BillingPeriodUnit.YEAR
            ) {
              // yearly subscription
              return constants.SubscriptionPosition.YEARLY;
            } else {
              // unknown subscription position
              return constants.SubscriptionPosition.UNKNOWN;
            }
          }
        }
      }
    }
  }
};

export {
  calculateRelativeDirection,
  calculateWindRelativeSpeed,
  getDirectionLabel,
  getSubscriptionPosition,
};
