import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormFeedback,
  Spinner,
} from "reactstrap";
import "./SignUpSection.scss";
import { useSelector, useDispatch } from "react-redux";
import TextInput from "components/common/Form/TextInput/TextInput";
import CheckBox from "components/common/Form/CheckBox/CheckBox";
import { signUp } from "redux/features/auth/authSlice";

const SignUpSection = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const authInfo = useSelector((state) => state?.authInfo);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (authInfo?.info?.email && !authInfo?.info?.emailVerified) {
      history.push("/verify-email");
    }
  }, [authInfo?.info?.email, authInfo?.info?.emailVerified]);
  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
  });

  // console.log(watch("email"));

  const onSubmit = async (data) => {
    // const response = await userService.register(data?.email, data.password);
    // console.log(response);
    dispatch(
      signUp({
        email: data?.email,
        password: data?.password,
        firstName: data?.firstName,
        lastName: data?.lastName,
      })
    );
  };

  useEffect(() => {
    if (isOpen) {
      renderModal();
    }
  }, [isOpen]);

  const handleShowModal = () => {
    setIsOpen(!isOpen);
    // history.push("./terms-and-conditions");
  };

  const handleAcceptTerm = () => {
    setTermsAccepted(true);
  };

  const handleDeclineTerm = () => {
    handleDeclineTerm(false);
  };

  const renderModal = () => {
    return (
      <Modal isOpen={isOpen} toggle={handleShowModal} className="tnc-modal">
        <ModalHeader toggle={handleShowModal}>Terms And Conditions</ModalHeader>
        <ModalBody>
          Please read this agreement carefully before accessing or using the
          information and services available through the Good Surf Now website
          (“Site”). By accessing or using the Site, you agree to be bound by the
          terms and conditions below. Good Surf Now may modify this agreement at
          any time, and such modifications shall be effective immediately upon
          posting the modified agreement on the Site. Advertiser terms and
          conditions must be accepted and adhered to by Advertisers who use Good
          Surf Now's services and the Site. Good Surf Now also has a
          comprehensive privacy policy for users of the Site.
        </ModalBody>
        <ModalFooter className="d-flex justify-content-end">
          <Button color="primary" onClick={handleAcceptTerm}>
            Accept
          </Button>
          <Button color="default" onClick={handleDeclineTerm} className="ml-3">
            Decline
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      {renderModal()}
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">Sign Up</h1>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <p className="text-start form-description">
              Create free account to get started
            </p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <Form onSubmit={handleSubmit(onSubmit)}>
              <TextInput
                placeholder="First Name"
                label="First Name"
                type="text"
                inputRef={register("firstName", {
                  required: {
                    value: true,
                    message: "This field first name is required",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9āēīōūĀĒĪŌŪ\s]{2,30}$/,
                    message:
                      "First name should be 2 to 30 characters and no special characters allowed",
                  },
                })}
                name="firstName"
                invalid={!!errors.firstName}
                error={errors?.firstName?.message}
              />
              <TextInput
                placeholder="Last Name"
                label="Last Name"
                type="text"
                inputRef={register("lastName", {
                  required: {
                    value: true,
                    message: "This field last name is required",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9āēīōūĀĒĪŌŪ\s]{2,30}$/,
                    message:
                      "Last name should be 2 to 30 characters and no special characters allowed",
                  },
                })}
                name="lastName"
                invalid={!!errors.lastName}
                error={errors?.lastName?.message}
              />
              <TextInput
                placeholder="Email"
                label="Email"
                type="email"
                inputRef={register("email", {
                  required: {
                    value: true,
                    message: "This field first name is required",
                  },
                  pattern: {
                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    message: "Should be a valid email address",
                  },
                })}
                name="email"
                invalid={!!errors.email}
                error={errors?.email?.message}
              />
              <TextInput
                placeholder="Password"
                label="Password"
                type="password"
                inputRef={register("password", {
                  required: {
                    value: true,
                    message: "This password name is required",
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,40}$/,
                    message:
                      "password should be 8 to 40 characters and contains at least one uppercase one lowercase and one number",
                  },
                })}
                name="password"
                invalid={!!errors.password}
                error={errors?.password?.message}
              />
              <CheckBox
                className="sign-up-check"
                type="checkbox"
                inputRef={register("tnc", { required: true })}
                invalid={!!errors.tnc}
                error="Terms and conditions needs to be accepted"
                content={
                  <>
                    I agree to the{" "}
                    <a href="javascript:void(0);" onClick={handleShowModal}>
                      terms and conditions
                    </a>
                    .
                  </>
                }
              />
              {authInfo?.error?.message ? (
                <FormFeedback>{authInfo?.error?.message}</FormFeedback>
              ) : (
                <div />
              )}
              {authInfo?.error?.message && (
                <span className="text-danger ml-2 mt-1 d-block">
                  {authInfo?.error?.message}
                </span>
              )}
              <Button block className="login-button" type="submit">
                {authInfo.status === "pending" ? (
                  <Spinner size="sm" type="grow" />
                ) : (
                  `Sign Up ${" "}`
                )}
              </Button>
            </Form>
            <div className="extra-links-sign-up">
              <Button
                className="create-account"
                color="neutral"
                tag={Link}
                to="/sign-in"
              >
                I have an Account
              </Button>
            </div>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SignUpSection;
