import React, { useEffect, useState } from "react";
import { Row, Col, Container, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import "./PackagesSection.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectSubscription,
  getPackages,
} from "redux/features/subscription/subscriptionSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import constants from "../../constants";
import shaka from "../../assets/icons/shaka.png";
import CenterSpinner from "components/Spinner/CenterSpinner";

const PackagesSection = () => {
  const info = useSelector((state) => state.subscription.info);
  const error = useSelector((state) => state.subscription.error);
  const [errorVisible, setErrorVisible] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (error && !errorVisible) {
      toast.warn(error?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: "Bounce",
      });
      setErrorVisible(true);
    }
  }, [error, errorVisible]);
  useEffect(() => {
    dispatch(getPackages());
  }, []);

  const handleSelectPackage = (packageId) => {
    dispatch(selectSubscription(packageId));
    history.push("/checkout");
  };

  const handleClickSkipNow = () => {
    history.push("/");
  };

  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start packages-title">Premium Membership</h1>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="7">
            <p className="text-start packages-description">
              Cheap-as! From just 11c per day for access to NZ's best surf cams
            </p>
          </Col>
        </Row>
        {info?.packages ? (
          <Row className="mt-4">
            <Col className="ml-auto mr-auto" md="7">
              <Row className="p-0 m-0 mb-2">
                {_.map(
                  _.orderBy(info?.packages, (p) => p.billingAmount, "asc"),
                  (pac, index) => (
                    <Col className="p-0 m-0 g-0" xs={12} md={6} key={index}>
                      <div
                        className={`plan ${
                          pac?.billingPeriodUnit ===
                          constants.BillingPeriodUnit.YEAR
                            ? "plan-yearly"
                            : "plan-monthly"
                        }`}
                        onClick={() => handleSelectPackage(pac?.id)}
                      >
                        {pac?.billingPeriodUnit ===
                        constants.BillingPeriodUnit.YEAR ? (
                          <div className="yearly-promotion-container">
                            <img
                              src={shaka}
                              alt="live_cam"
                              style={{ width: "30px", height: "22.2px" }}
                            ></img>
                            <div className="yearly-save-text">
                              <span className="save-value">SAVE 36%</span>{" "}
                              CHUUUURRR!!!
                            </div>
                          </div>
                        ) : (
                          <div className="monthly-promotion-container" />
                        )}
                        <br />
                        <h3>{pac.name}</h3>
                        <p className="free-trial-text">
                          {pac.freeTrialLength
                            ? `${pac.freeTrialLength}-${pac.freeTrialUnit} free trial`
                            : "no trial"}
                        </p>
                        <div className="package-price-container">
                          <div
                            className="package-price-button"
                            style={{
                              backgroundColor:
                                pac?.billingPeriodUnit ===
                                constants.BillingPeriodUnit.YEAR
                                  ? "#fd2dbf"
                                  : "#231F20",
                            }}
                          >{`$${pac.monthlyCost}/ month`}</div>
                        </div>
                        <br />
                        {pac?.billingPeriodUnit ===
                        constants.BillingPeriodUnit.YEAR ? (
                          <div className="billing-cycle">{`$${pac.billingAmount} NZD billed once a ${pac.billingPeriodUnit}`}</div>
                        ) : (
                          <div className="billing-cycle">{`$${pac.billingAmount} NZD billed monthly`}</div>
                        )}
                        <br />
                        <div className="auto-renewal">{`Cancel auto-renewal anytime in account settings`}</div>
                        <br />
                      </div>
                    </Col>
                  )
                )}
              </Row>
              {/* Banner */}
              <Row>
                <Col className="ml-auto mr-auto" md="4">
                  <div className="generic-account-ad-slot-container generic-ad-slot-center">
                    <img
                      src="https://gsn-photos.s3.ap-southeast-2.amazonaws.com/rectangle_maz_d0786e2f31.jpg"
                      className="generic-ad-slot"
                    />
                  </div>
                </Col>
              </Row>
              <div className="skip-now-container" onClick={handleClickSkipNow}>
                <span className="skip-now-text">skip for now</span>
              </div>
            </Col>
          </Row>
        ) : (
          <div style={{ height: "396.91px" }}>
            <CenterSpinner type="border" size="lg" />
          </div>
        )}
      </Container>
    </div>
  );
};
export default PackagesSection;
