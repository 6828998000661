import { Auth } from "aws-amplify";

class AuthService {
  /**
   * login with amplify using email and password
   * @param {*} username
   * @param {*} password
   */
  async login(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      return {
        email: user?.attributes?.email,
        emailVerified: user?.attributes?.email_verified,
        userId: user?.attributes["custom:gsnUserId"],
        signedIn: true,
      };
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async logOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  async getCurrentUserAmplify() {
    try {
      const isValid = (await Auth.currentSession()).isValid();
      if (isValid) {
        const results = await Auth.currentAuthenticatedUser();
        return {
          email: results?.attributes?.email,
          emailVerified: results?.attributes?.email_verified,
          signedIn: isValid,
        };
      } else {
        throw Error("Not signed in");
      }
    } catch (err) {
      throw err;
    }
  }
}

const authService = new AuthService();

export default authService;
