
import React from "react";
import {Row, Col, Container} from 'reactstrap';
import Description from "components/Description/Description";
import "./PrivacyPolicySection.scss";

const PrivacyPolicySection = ({ data }) => {
  return (
    <div className="section section-sections" style={{minHeight:'600px', paddingBottom: '20px'}}>
      <Container>
          <Row>
              <Col className="ml-auto mr-auto" md="8">
                <div className="text-start privacy-policy-title">
                    Privacy Policy
                </div>
              </Col>
          </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                {data && data.description ? <Description value={data.description} /> : <span />}
                </Col>
            </Row>
      </Container>
    </div>
  );
};
export default PrivacyPolicySection;
