import React, { useState } from "react";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";
import logoDesktop from "../../assets/logo/gsn_logo_desktop.png";
import logoMobile from "../../assets/logo/gsn_logo_mobile.png";
// import navIcon from '../../assets/nav/nav_icon.png';
import navIcon from "../../assets/nav/forecast_burger_icon.png";
import avatar from "../../assets/nav/seal_icon.png";
import navIconMobile from "../../assets/nav/nav_icon.png";
// import { FaBars } from 'react-icons/fa';
import LocationFooter from "../Locations/LocationFooter";
import "./GoodSurfNavBar.scss";

// reactstrap components
import {
  Collapse,
  Col,
  Row,
  Navbar,
  Container,
  UncontrolledDropdown,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "redux/features/auth/authSlice";
import { mobileModel } from "react-device-detect";

const GoodSurfNavBar = ({ locations }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const user = useSelector((state) => state?.authInfo?.info);

  const handleClickLogo = () => {
    history.push({
      pathname: "/",
    });
  };

  const handleToggle = () => {
    document.documentElement.classList.toggle("nav-open");
    setCollapseOpen(!collapseOpen);
  };

  const handleClickFreeTrial = () => {
    history.push({
      pathname: "/sign-up",
    });
  };

  const handleClickLogin = () => {
    history.push({
      pathname: "/sign-in",
    });
  };
  const handleSignIn = () => {
    console.log("handle sign in ");
    history.push({
      pathname: "/sign-in",
    });
  };
  const handleClickMyAccount = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/account",
    });
  };

  const handleSignOut = (e) => {
    e.preventDefault();
    history.replace({
      pathname: "/",
    });
    dispatch(signOut());
  };

  const handleGetPremium = (e) => {
    e.preventDefault();
    history.push({
      pathname: "/packages",
    });
  };
  return (
    <>
      <Navbar className="bg-white fixed-top" expand="lg">
        <Container className="pl-0 pr-0">
          <Row className="m-0 p-0 navbar-translate good-surf-nav-bar">
            <Col xs="4" className="p-0 d-none d-sm-block">
              {/* <FaBars color="purple" size="30" onClick={handleToggle} className="good-surf-menu"/> */}
              <div className="gsn-nav-icon" onClick={handleToggle}>
                <img
                  alt="gsn-nav"
                  className="location-img"
                  src={navIcon}
                  style={{ width: "150px", height: "25px" }}
                />
              </div>
            </Col>
            <Col xs="4" className="p-0 d-block d-sm-none">
              {/* <FaBars color="purple" size="30" onClick={handleToggle} className="good-surf-menu"/> */}
              <div className="gsn-nav-icon" onClick={handleToggle}>
                <img
                  alt="gsn-nav"
                  className="location-img"
                  src={navIconMobile}
                  style={{ width: "30px", height: "25px" }}
                />
              </div>
            </Col>
            <Col
              xs="4"
              className="pl-0 pr-0 ml-0 mr-0 desktop-icon d-none d-sm-flex"
            >
              <img
                src={logoDesktop}
                alt="Good Surf Now"
                style={{ width: "146px", height: "30px" }}
                onClick={handleClickLogo}
              ></img>
            </Col>
            <Col
              xs="4"
              className="pl-0 pr-0 ml-0 mr-0 desktop-icon d-flex d-sm-none"
            >
              {/* <img
                src={logoMobile}
                alt="Good Surf Now"
                style={{ width: "30px", height: "30px" }}
                onClick={handleClickLogo}
              ></img> */}
              <img
                src={logoDesktop}
                alt="Good Surf Now"
                style={{ width: "146px", height: "30px" }}
                onClick={handleClickLogo}
              ></img>
            </Col>
            <Col xs="4" className="pl-0 pr-0 ml-0 mr-0 desktop-dropdown-icon">
              <div className="gsn-nav-icon">
                {user?.signedIn ? (
                  <Dropdown isOpen={isOpenMenu} inNavbar>
                    <DropdownToggle
                      aria-haspopup={true}
                      caret
                      color="default"
                      data-toggle="dropdown"
                      // href="http://example.com"
                      id="navbarDropdownMenuLink"
                      nav
                      onClick={() => setIsOpenMenu(!isOpenMenu)}
                      // direction="up"
                    >
                      {/* <p className="avatar-dropdown-text">CHUUURRR </p> */}
                      <img
                        alt="gsn-nav"
                        className="location-img"
                        src={avatar}
                        style={{ width: "41px", height: "40px" }}
                      />
                    </DropdownToggle>
                    <DropdownMenu
                      aria-labelledby="navbarDropdownMenuLink"
                      className="avatar-dropdown-menu"
                      right={true}
                    >
                      <DropdownItem
                        className="avatar-dropdown-item"
                        href="#pablo"
                        onClick={handleClickMyAccount}
                      >
                        My account
                        <div className="my-acc-dropdown-text">Free version</div>
                      </DropdownItem>
                      <DropdownItem
                        className="avatar-dropdown-premium-item"
                        href="#pablo"
                        onClick={handleGetPremium}
                        text
                        style={{ fontSize: "16px", marginTop: "-20px" }}
                      >
                        Get Premium
                      </DropdownItem>

                      {/* <DropdownItem
                        className="avatar-dropdown-item"
                        href="#pablo"
                        onClick={handleClickMyAccount}
                      >
                        Packages
                      </DropdownItem> */}
                      <DropdownItem
                        className="avatar-dropdown-item"
                        href="#pablo"
                        onClick={handleSignOut}
                      >
                        Sign Out
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                ) : (
                  <div>
                    <button
                      className="login-button-nav"
                      onClick={() => handleClickLogin()}
                    >
                      LOGIN
                    </button>
                    <button
                      className="free-trial-button-nav"
                      onClick={() => handleClickFreeTrial()}
                    >
                      SIGN UP
                    </button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Collapse isOpen={collapseOpen} navbar>
            <LocationFooter
              locations={locations}
              navbar
              onClose={handleToggle}
            />
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default GoodSurfNavBar;
