import React from "react";
import ReactDOM from "react-dom/client";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
// import InstallPWA from './components/InstallPWA/InstallPWA';
import { HelmetProvider } from "react-helmet-async";
// import ContactUs from './views/examples/ContactUs';
import store from "./redux/store";
import { Provider } from "react-redux";
import { configure } from "amplify/Config";
import Routes from "routes";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";

// others
const tagManagerArgs = {
  gtmId: "GTM-T43TFKJ",
};

const helmetContext = {};

TagManager.initialize(tagManagerArgs);

// configure amplify
configure();

const root = ReactDOM.createRoot(document.getElementById("root"));
let persistor = persistStore(store);
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <HelmetProvider context={helmetContext}>
        <BrowserRouter>
          {
            /* <InstallPWA/> */
            <Routes />
          }
        </BrowserRouter>
      </HelmetProvider>
    </PersistGate>
  </Provider>
);
