import { API } from "aws-amplify";

class AmplifyApiService {
  async get(endpoint) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/${endpoint}`;
    const myInit = {
      headers: {}, // OPTIONAL
    };

    return API.get(apiName, path, myInit);
  }

  async post(endpoint, body) {
    const apiName = process.env.REACT_APP_API_NAME;
    const path = `/${endpoint}`;
    const myInit = {
      headers: {}, // OPTIONAL
      body,
    };

    return API.post(apiName, path, myInit);
  }
}

const amplifyApiService = new AmplifyApiService();
export default amplifyApiService;
