import _ from "lodash";

class LocalStorageService {
  setLocation(location) {
    const recentlyVisited =
      JSON.parse(localStorage.getItem("recentlyVisited")) || [];
    const index = _.findIndex(
      recentlyVisited,
      (page) => page.id === location.id
    );
    if (index > -1) {
      // remove the item if it found
      recentlyVisited.splice(index, 1);
    }
    if (recentlyVisited.length >= 10) {
      // remove the oldest location if there are more than 5
      recentlyVisited.shift();
    }
    recentlyVisited.push({
      id: location.id,
      slug: location.slug,
      label: location.name,
    });
    // Save the updated list to local storage.
    localStorage.setItem("recentlyVisited", JSON.stringify(recentlyVisited));
    return recentlyVisited;
  }

  removeLocation(slug) {
    const recentlyVisited =
      JSON.parse(localStorage.getItem("recentlyVisited")) || [];
      const index = _.findIndex(
        recentlyVisited,
        (page) => page.slug === slug
      );
      if (index > -1) {
        // remove the item if it found
        recentlyVisited.splice(index, 1);
      }
      // Save the updated list to local storage.
    localStorage.setItem("recentlyVisited", JSON.stringify(recentlyVisited));
    return recentlyVisited;
  }

  getLocations() {
    const values = JSON.parse(localStorage.getItem("recentlyVisited")) || [];
    return _.reverse(values);
  }

  setAuthCredentials(data) {
    const {
      user: { email, username },
      jwt,
    } = data || { user: {} };
    localStorage.setItem("username", username);
    localStorage.setItem("email", email);
    localStorage.setItem("jwt", jwt);
  }

  removeAuthCredentials(data) {
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("jwt");
  }
}

const localStorageService = new LocalStorageService();
export default localStorageService;
