import React from "react";
import { RotatingLines } from "react-loader-spinner";
import greyBackground from "./../../assets/loader/greyBackground.jpg"
import "./Loader.scss";

const Loader = () => {
    return (
        <div className="gsn-post-loader">
            <img
                style={{ width: '100%' }}
                alt="..."
                className="img rounded img-raised "
                src={greyBackground}
            ></img>
            <div className="gsn-post-loader">
                <RotatingLines
                    strokeColor="grey"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="50"
                    visible={true}
                    wrapperClass="gsn-post-loader"
                />
            </div>

        </div>
    )
};

export default Loader;
