import React from "react";
import {Row, Col, Container} from 'reactstrap';
import Description from "components/Description/Description";
import "./AboutUsSection.scss";

const AboutUsSection = ({ data }) => {
  return (
    <div className="section section-sections" style={{minHeight:'600px', paddingBottom: '20px'}}>
      <Container>
          <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h1 className="text-start about-us-title">
                    About Us
                </h1>
              </Col>
          </Row>
            <Row>
                <Col className="ml-auto mr-auto" md="8">
                {data && data.description ? <Description value={data.description} /> : <span />}
                </Col>
            </Row>
      </Container>
    </div>
  );
};
export default AboutUsSection;
