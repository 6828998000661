import { Amplify } from "aws-amplify";

const configure = () => {
  const auth = {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,

    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_CLIENT_ID,

    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: process.env.REACT_APP_AWS_OAUTH_DOMAIN,
      // scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_REDIRECT_SIGN_IN}`,
      redirectSignOut: `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_REDIRECT_SIGN_OUT}`,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  };

  const api = {
    endpoints: [
      {
        name: process.env.REACT_APP_API_NAME,
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: process.env.REACT_APP_API_REGION,
      },
    ],
  };

  const storage = {
    AWSS3: {
      bucket: "gsn-uploads", //REQUIRED -  Amazon S3 bucket name
      region: "ap-southeast-2", //OPTIONAL -  Amazon service region
    },
  };

  Amplify.configure({
    Auth: auth,
    API: api,
    Storage: storage,
  });
};

export { configure };

// reference
// Amplify.configure({
//     Auth: {
//       // (required) only for Federated Authentication - Amazon Cognito Identity Pool ID
//       identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

//       // (required)- Amazon Cognito Region
//       region: 'XX-XXXX-X',

//       // (optional) - Amazon Cognito Federated Identity Pool Region
//       // Required only if it's different from Amazon Cognito Region
//       identityPoolRegion: 'XX-XXXX-X',

//       // (optional) - Amazon Cognito User Pool ID
//       userPoolId: 'XX-XXXX-X_abcd1234',

//       // (optional) - Amazon Cognito Web Client ID (26-char alphanumeric string, App client secret needs to be disabled)
//       userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',

//       // (optional) - Enforce user authentication prior to accessing AWS resources or not
//       mandatorySignIn: false,

//       // (optional) - Configuration for cookie storage
//       // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
//       cookieStorage: {
//         // - Cookie domain (only required if cookieStorage is provided)
//         domain: '.yourdomain.com',
//         // (optional) - Cookie path
//         path: '/',
//         // (optional) - Cookie expiration in days
//         expires: 365,
//         // (optional) - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
//         sameSite: 'strict' | 'lax',
//         // (optional) - Cookie secure flag
//         // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
//         secure: true
//       },

//       // (optional) - customized storage object
//       storage: MyStorage,

//       // (optional) - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
//       authenticationFlowType: 'USER_PASSWORD_AUTH',

//       // (optional) - Manually set key value pairs that can be passed to Cognito Lambda Triggers
//       clientMetadata: { myCustomKey: 'myCustomValue' },

//       // (optional) - Hosted UI configuration
//       oauth: {
//         domain: 'your_cognito_domain',
//         scope: [
//           'phone',
//           'email',
//           'profile',
//           'openid',
//           'aws.cognito.signin.user.admin'
//         ],
//         redirectSignIn: 'http://localhost:3000/',
//         redirectSignOut: 'http://localhost:3000/',
//         clientId: '1g0nnr4h99a3sd0vfs9',
//         responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
//       }
//     }
//   });
