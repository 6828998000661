import React, {memo} from 'react';
import './VideoPlayer.scss'
import ReactPlayer from 'react-player/lazy'


const VideoPlayer = ({
    link
}) => {
    return (
    <div className="player-wrapper">
        <ReactPlayer
        url={link}
        className="react-player"
        width="100%"
        height="100%"
        controls
        />
    </div>
    )
}

export default memo(VideoPlayer);
