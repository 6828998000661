import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterIcon,
  TwitterShareButton,
  TelegramIcon,
  TelegramShareButton,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import ChainLinkIcon from "../../assets/icons/chain-link-icon.svg";
import { IoIosLink } from "react-icons/io";
import "./SocialLinks.scss";
import { UncontrolledTooltip } from "reactstrap";

const SocialLinks = () => {
  const [copied, setCopied] = useState(false);
  const [message, setMessage] = useState("");
  const url = window.location.href;

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
    // setMessage("Link copied!");
    // setTimeout(() => {
    //   setCopied(false);
    // }, 2000); // Reset copied state and message after 2 seconds
  };

  return (
    <Row>
      <Col className="ml-auto mr-auto gsn-share-buttons" md="8">
        <FacebookShareButton
          size={32}
          round="true"
          url={window.location.href}
          className="gsn-share-button"
        >
          <FacebookIcon size={32} round="true" />
        </FacebookShareButton>
        <FacebookShareButton
          size={32}
          round="true"
          url={window.location.href}
          className="gsn-share-button"
        >
          <FacebookMessengerIcon size={32} round="true" />
        </FacebookShareButton>
        <TwitterShareButton
          size={32}
          round="true"
          url={window.location.href}
          className="gsn-share-button"
        >
          <TwitterIcon size={32} round="true" />
        </TwitterShareButton>
        <TelegramShareButton
          size={32}
          round="true"
          url={window.location.href}
          className="gsn-share-button"
        >
          <TelegramIcon size={32} round="true" />
        </TelegramShareButton>
        <WhatsappShareButton
          size={32}
          round="true"
          url={window.location.href}
          className="gsn-share-button"
        >
          <WhatsappIcon size={32} round="true" />
        </WhatsappShareButton>
        <EmailShareButton
          size={32}
          round="true"
          url={window.location.href}
          className="gsn-share-button"
        >
          <EmailIcon size={32} round="true" />
        </EmailShareButton>

        {copied && <p>{message}</p>}
        <div
          className="icon-circle"
          id="ScheduleUpdateTooltip"
          onClick={handleCopy}
        >
          <IoIosLink size={18} color="#fff" />
          <UncontrolledTooltip
            placement="top"
            target="ScheduleUpdateTooltip"
            trigger="click"
            className="tooltip-container-class"
          >
            {" "}
            Copied{" "}
          </UncontrolledTooltip>
        </div>
      </Col>
    </Row>
  );
};

export default SocialLinks;
