import React from "react";
import { Col, Card, CardTitle, CardBody } from "reactstrap";
import moment from "moment";
import './PostCards.scss';

const PostCards = ({ post, className, onSelect }) => {
  return (
    <Col xs={12} lg="4" md="6" className={`pl-0 pr-0 ${className}`}>
      <div className="post-card" style={{paddingTop: '0px'}}>
        <Card className="card-blog">
          <div className="card-image">
            <img alt="..." className="img rounded" src={post.thumbnail.url} onClick={() => onSelect(post)}></img>
          </div>
          <CardBody>
            <h6 className="category text-warning">
              <i className="now-ui-icons business_bulb-63"></i> {post.category}
            </h6>
            <CardTitle tag="h5">
              <a
                href="#pablo"
                style={{ color: "#2c2c2c" }}
                onClick={(e) => {
                  onSelect(post);
                  e.preventDefault();
                }}
              >
                {post.title}
              </a>
            </CardTitle>
            <p className="card-description">
              <b>{post.short_description}</b>
            </p>
            <p className="post-date">
              <b>{moment(post.created_at).format("MMM DD, YYYY")}</b>
            </p>
          </CardBody>
        </Card>
      </div>
    </Col>
  );
};

export default PostCards;
