import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Card, Col } from "reactstrap";
import useIosInstallPrompt from "../../hooks/useIosInstallPrompt";
import useWebInstallPrompt from "../../hooks/useWebInstallPrompt";
import gsnLogo from "../../assets/logo/gsn_logo_mobile.png";
import "./InstallPWABanner.scss";
import { MdClose } from "react-icons/md";
import InstallPWAIosPrompt from "./InstallPWAIosPrompt";
import getAppBanner from "../../assets/banners/get_app_banner.png";

const InstallPWABanner = () => {
  const [iosInstallPrompt, handleIOSInstallDeclined] = useIosInstallPrompt();
  const [webInstallPrompt, handleWebInstallDeclined, handleWebInstallAccepted] =
    useWebInstallPrompt();
  const [show, setShow] = useState(false);

  const displayBanner = (accepted, declined) => (
    <Col xs={12} className="pl-0 pr-0 d-block d-lg-none">
      <div className="install-pwa-banner">
        <InstallPWAIosPrompt
          show={show}
          onClose={handleCloseInstallPWAIosPrompt}
        />
        <MdClose
          size={20}
          color="#FD2DBF"
          onClick={declined}
          className="pwa-close-button"
        />
        <Card className="card-blog" onClick={accepted}>
          {/* <div className="pwa-banner"> */}
          <img
            alt="pwa-banner"
            src={getAppBanner}
            className="img rounded img-raised pwa-banner-image"
            style={{
              width: "100%",
              height: "17.6%",
              boxShadow: "none !important",
              borderRadius: "0px !important",
            }}
            onClick={accepted}
          />
          {/* </div> */}
        </Card>
      </div>
    </Col>
  );

  const handleCloseInstallPWAIosPrompt = () => {
    setShow(false);
    handleIOSInstallDeclined();
  };
  const handleClickBannerIos = () => {
    setShow(true);
  };

  if (!iosInstallPrompt && !webInstallPrompt) {
    return null;
  }
  if (iosInstallPrompt && isMobile) {
    return displayBanner(handleClickBannerIos, handleIOSInstallDeclined);
  }
  if (webInstallPrompt && isMobile) {
    return displayBanner(handleWebInstallAccepted, handleWebInstallDeclined);
  }
  return null;
};

export default InstallPWABanner;
