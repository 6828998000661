import React, { useState, useEffect } from "react";
// reactstrap components
import "./HomeSections.scss";
import ForecastSection from "./ForecastSection/ForecastSection";
import GenericSection from "./GenricSection/GenericSection";
import HomeDescription from "./HomeDescription/HomeDescription";
import AdSlot from "components/Ads/AdSlot";
import ApiService from "services/ApiService";
import constants from "../../constants";

// core components

const HomeSections = ({ onSelectPost }) => {
  const [homeContent, setHomeContent] = useState(null);

  useEffect(() => {
    (async function callApi() {
      try {
        const results = await ApiService.get("home-content");
        setHomeContent(results);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <>
      <div className="" data-background-color="#FFFFFF">
        <HomeDescription />
        <AdSlot
          id={constants.HomeAdSlots.ONE}
          genericAds={homeContent}
          bottomSpaced
        />
        <ForecastSection onSelectPost={onSelectPost} />
        <GenericSection onSelectPost={onSelectPost} ads={homeContent} />
        <AdSlot
          id={constants.HomeAdSlots.SIX}
          genericAds={homeContent}
          bottomSpaced
        />
      </div>
    </>
  );
};

export default HomeSections;
