import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Label,
  Card,
  CardBody,
  CardText,
  CardTitle,
} from "reactstrap";
import "./SubscriptionSection.scss";
import TextInput from "components/common/Form/TextInput/TextInput";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { confirm } from "redux/features/auth/authSlice";

const SubscriptionSection = ({ data }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.authInfo?.info);
  const history = useHistory();

  const {
    register,
    handleSubmit,
    // watch,
    formState: { errors },
  } = useForm({
    // mode: "onChange",
  });

  //   useEffect(() => {
  //     if (user?.email && user?.emailVerified) {
  //       history.push("/sign-in");
  //     } else if (!user?.email && !user?.emailVerified) {
  //       history.push("/");
  //     }
  //   }, [user?.email, user?.emailVerified]);

  const onSubmit = (data) => {
    dispatch(
      confirm({
        email: user?.email,
        code: data?.code,
      })
    );
  };
  return (
    <div className="section section-sections" style={{ paddingBottom: "20px" }}>
      <Container style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <h1 className="text-start login-title">Select Subscription</h1>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="4">
            <div className="subscription-container">
              <Card className="card-blog">
                <CardBody>
                  <CardTitle tag="h5">Yearly</CardTitle>
                  <div>
                    <Label>129 $</Label>
                  </div>
                </CardBody>
              </Card>
              <Card className="card-blog">
                <CardBody>
                  <CardTitle tag="h5">Monthly</CardTitle>
                  <div>
                    <Label>29 $</Label>
                  </div>
                </CardBody>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default SubscriptionSection;
