import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import "./Promotion.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getSubscriptionPosition } from "tools/utills";
import constants from "../../constants";
// import banner from "../../assets/banners/banner_marketing.png";

const Promotion = () => {
  const user = useSelector((state) => state?.user?.info);
  const history = useHistory();
  const [subscriptionPosition, setSubscriptionPosition] = useState();

  useEffect(() => {
    const position = getSubscriptionPosition(user);
    setSubscriptionPosition(position);
  }, [user]);

  const handleClick = () => {
    history.push("/post/gsn-premium-camsfeb1724");
  };

  // if (
  //   subscriptionPosition !== constants.SubscriptionPosition.TRIAL &&
  //   subscriptionPosition !== constants.SubscriptionPosition.MONTHLY &&
  //   subscriptionPosition !== constants.SubscriptionPosition.YEARLY &&
  //   subscriptionPosition !== constants.SubscriptionPosition.EXPIRED
  // ) {
  //   return (
  //     <div className="promotion-container">
  //       <Row className="promotion-text-row">
  //         <h6 className="promotion-text promotion-link" onClick={handleClick}>
  //           New
  //         </h6>
  //         <h6
  //           className="promotion-text"
  //           style={{ fontStyle: "italic", fontSize: "20px" }}
  //           onClick={handleClick}
  //         >
  //           Surf Cams!!!
  //         </h6>
  //         <h6
  //           className="promotion-text promotion-text-value"
  //           style={{ fontStyle: "italic" }}
  //           onClick={handleClick}
  //         >
  //           Check them out now!
  //         </h6>
  //       </Row>
  //     </div>
  //   );
  // } else {
  //   return <div />;
  // }

  return (
    <Container
      className="free-trial-slot-container"
      style={{
        backgroundColor: "black",
        // margin: "0px",
      }}
    >
      <Row>
        <Col className="ml-auto mr-auto" xs="12" md="8">
          <div className={`${"free-trial-slot-center"}`}>
            <Row className="promotion-text-row">
              <h6
                className="promotion-text promotion-link"
                onClick={handleClick}
              >
                New
              </h6>
              <h6
                className="promotion-text"
                style={{ fontStyle: "italic", fontSize: "20px" }}
                onClick={handleClick}
              >
                Surf Cams!!!
              </h6>
              <h6
                className="promotion-text promotion-text-value"
                style={{ fontStyle: "italic", color: "#fd2dbf" }}
                onClick={handleClick}
              >
                Click here.
              </h6>
              <h6
                className="promotion-text promotion-text-value"
                style={{ fontStyle: "italic" }}
                onClick={handleClick}
              >
                Free for a limited time
              </h6>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
  // return (
  //   <div className="promotion-container">
  //     <Row className="promotion-text-row">
  //       <h6 className="promotion-text promotion-link" onClick={handleClick}>
  //         New
  //       </h6>
  //       <h6
  //         className="promotion-text"
  //         style={{ fontStyle: "italic", fontSize: "20px" }}
  //         onClick={handleClick}
  //       >
  //         Surf Cams!!!
  //       </h6>
  //       <h6
  //         className="promotion-text promotion-text-value"
  //         style={{ fontStyle: "italic" }}
  //         onClick={handleClick}
  //       >
  //         Check them out now!
  //       </h6>
  //     </Row>
  //   </div>
  // );
};

export default Promotion;
