import React from "react";
import { FaArrowUp } from "react-icons/fa";
import { GoPrimitiveDot } from "react-icons/go";
import { calculateRelativeDirection, calculateWindRelativeSpeed } from "../../tools/utills";
import "./WindArrow.scss";

const WindArrow = ({ speed, direction, iconColor }) => {
  return (
    <div className="wind-direction">
      {speed === 0 || speed === 1 ? (
        <GoPrimitiveDot size={7} style={{ color: "#234567" }} />
      ) : (
        <FaArrowUp
          color={iconColor || "#1374DF"}
          size={calculateWindRelativeSpeed(speed)}
          style={{ transform: `rotate(${calculateRelativeDirection(direction)}deg)` }}
        />
      )}
    </div>
  );
};

export default WindArrow;
