import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardTitle } from "reactstrap";
import _ from "lodash";
import "../HomePostsSection.scss";
import ApiService from "services/ApiService.js";
import moment from "moment";
import PostCards from "../../../components/PostCards/PostCards";
import InstallPWABanner from "components/InstallPWA/InstallPWABanner";
import AdSlot from "components/Ads/AdSlot";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import AdSpacer from "../../../components/common/AdSpacer";
import constants from "../../../constants";
import Loader from "components/Loader/Loader";

const GenericSection = ({ onSelectPost, ads }) => {
  const [page, setPage] = useState(1);
  const [posts, setPosts] = useState([]);
  const { width } = useWindowDimensions();

  useEffect(() => {
    (async function callApi() {
      try {
        const posts = await ApiService.get(
          `posts?_limit=${page * 9 + 1}&_sort=id:DESC&zone_eq=generic`
        );
        setPosts(posts);
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [page]);

  /**
   * render the fist post in an image container
   * only after medium size screen
   * @param {*} post
   * @returns
   */
  const renderMainPost = (post) => (
    <Row className="p-0 m-0">
      <Col className="p-0 m-0 d-none d-md-flex">
        {post ? (
          <div className="main-post">
            <img
              style={{ width: "100%", height: "100%" }}
              alt="..."
              className="img rounded img-raised "
              src={post.thumbnail.url}
              onClick={() => onSelectPost(post)}
            ></img>
            <div className="main-post-overlay img-raised">
              <CardTitle tag="h3">
                <a
                  href="#pablo"
                  onClick={(e) => {
                    onSelectPost(post);
                    e.preventDefault();
                  }}
                >
                  <b>{post.title}</b>
                </a>
              </CardTitle>
              {/* <p className="card-description"><b>{post.short_description}</b></p> */}
              <p className="main-post-date">
                <b>{moment(post.created_at).format("MMM DD, YYYY")}</b>
              </p>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  );

  const getPostCards = () => {
    const cards = _.map(posts, (post, index) => (
      <PostCards
        key={index}
        post={post}
        className={!index ? "d-flex d-md-none" : ""}
        onSelect={onSelectPost}
      />
    ));
    cards.splice(1, 0, <div />);
    // mobile to tab
    if (width < 992 && cards.length > 2) {
      const adSlot2 =
        ads && ads.ad_slot2_image && !ads.ad_slot2_hide ? (
          <div key="ad-2-mobile" style={{ width: "100%" }}>
            <AdSlot
              id={constants.HomeAdSlots.TWO}
              genericAds={ads}
              bottomSpaced
            />
          </div>
        ) : (
          <span key="ad-2-mobile" />
        );
      cards.splice(2, 0, adSlot2);
    }
    // tab pro
    if (width >= 992 && cards.length > 8) {
      const adSlot2 =
        ads && ads.ad_slot3_image && !ads.ad_slot3_hide ? (
          <div key="ad-2-desktop" style={{ width: "100%" }}>
            <AdSlot
              id={constants.HomeAdSlots.THREE}
              genericAds={ads}
              bottomSpaced
            />
          </div>
        ) : (
          <span key="ad-2-desktop" />
        );
      cards.splice(8, 0, adSlot2);
    }
    if (width < 992 && cards.length > 7) {
      const adSlot3 =
        ads && ads.ad_slot3_image && !ads.ad_slot3_hide ? (
          <div key="ad-3-mobile" style={{ width: "100%" }}>
            <AdSlot
              id={constants.HomeAdSlots.THREE}
              genericAds={ads}
              bottomSpaced
            />
          </div>
        ) : (
          <span key="ad-3-mobile" />
        );
      cards.splice(7, 0, adSlot3);
    }
    if (width >= 992 && cards.length > 15) {
      const adSlot3 =
        ads && ads.ad_slot4_image && !ads.ad_slot4_hide ? (
          <div key="ad-3-desktop" style={{ width: "100%" }}>
            <AdSlot
              id={constants.HomeAdSlots.FOUR}
              genericAds={ads}
              bottomSpaced
            />
          </div>
        ) : (
          <span key="ad-3-desktop" />
        );
      cards.splice(15, 0, adSlot3);
    }
    if (width < 992 && cards.length > 12) {
      const adSlot4 =
        ads && ads.ad_slot4_image && !ads.ad_slot4_hide ? (
          <div key="ad-4-mobile" style={{ width: "100%" }}>
            <AdSlot
              id={constants.HomeAdSlots.FOUR}
              genericAds={ads}
              bottomSpaced
            />
          </div>
        ) : (
          <span key="ad-4-mobile" />
        );
      cards.splice(12, 0, adSlot4);
    }
    if (width >= 992 && cards.length > 22) {
      const adSlot4 =
        ads && ads.ad_slot5_image && !ads.ad_slot5_hide ? (
          <div key="ad-4-desktop" style={{ width: "100%" }}>
            <AdSlot
              id={constants.HomeAdSlots.FIVE}
              genericAds={ads}
              bottomSpaced
            />
          </div>
        ) : (
          <span key="ad-4-desktop" />
        );
      cards.splice(22, 0, adSlot4);
    }
    if (width < 992 && cards.length > 16) {
      const adSlot5 =
        ads && ads.ad_slot5_image && !ads.ad_slot5_hide ? (
          <div key="ad-5-mobile" style={{ width: "100%" }}>
            <AdSlot
              id={constants.HomeAdSlots.FIVE}
              genericAds={ads}
              bottomSpaced
            />
          </div>
        ) : (
          <span key="ad-5-mobile" />
        );
      cards.splice(16, 0, adSlot5);
    }
    return cards;
  };

  return (
    <Container className="pl-0 pr-0">
      <div className="forcast-home">
        <InstallPWABanner key="qpwa" />
        {renderMainPost(posts?.length ? posts[0] : null)}
        <AdSpacer />
        {width >= 992 && ads && ads.ad_slot2_image && !ads.ad_slot2_hide ? (
          <div className="d-none d-sm-block">
            <AdSlot
              id={constants.HomeAdSlots.TWO}
              genericAds={ads}
              bottomSpaced
            />
          </div>
        ) : (
          <span />
        )}
        <div className="home-other-posts">
          <Row>{getPostCards()}</Row>
          <div className="load-more" onClick={() => setPage(page + 1)}>
            <div>LOAD MORE</div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default GenericSection;
